import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {  Button, Dialog, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import Autosuggest from 'react-autosuggest';
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  filterButton: {
    marginLeft: 'auto'
  },
  filterIcon: {
    marginRight: theme.spacing(1)
  },
  search: {
    flexGrow: 1,
    height: 42,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon
  },
  searchInput: {
    flexGrow: 1
  },
  container: {
    position: 'relative',
    flexGrow: 1,
    backgroundColor: '#fff',
    border: '1px solid #eaeaea',
  },
  input: {
    width: '90%',
    height: 30,
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 300,
    fontSize: 16,
    border: 'none',
    padding: '5px 20px'
  },
  inputFocused: {
    outline: 'none'
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  suggestionsContainer: {
    display: 'none'
  },
  suggestionsContainerOpen: {
    border: '1px solid #eaeaea',
    display: 'block',
    position: 'absolute',
    width: '100%',
    backgroundColor: '#fff',
    fontFamily: 'Helvetica, sans-serif',
    fontWeight: 100,
    fontSize: 14,
    zIndex: 2
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  suggestion: {
    cursor: 'pointer',
    padding: '10px 20px'
  },
  suggestionHighlighted: {
    backgroundColor: '#ddd'
  },
  column: {
    display: 'flex',
    flexDirection: 'column'
  }
}));

const getSuggestions = (value, suggestions) => {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;

  return inputLength === 0 ? [] : suggestions.filter(suggestion =>
    suggestion.sku.toLowerCase().slice(0, inputLength) === inputValue || suggestion.title.toLowerCase().slice(0, inputLength) === inputValue
  );
};


const getSuggestionValue = suggestion => suggestion.sku;

const renderSuggestion = suggestion => (
  <div style={{display: 'flex', flexDirection: 'column'}}>
    <Typography>{suggestion.sku}</Typography>
    <Typography variant='subtitle2'>{suggestion.title}</Typography>
  </div>
);


const SearchBar = props => {
  const { onSearch, inputType, skuList, openDiv, togglePanel, handleCalendarAccept, startDate, endDate, OrdersDatePicker } = props;

  const classes = useStyles();
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const handleSubmit = (event) => {
    event.preventDefault();
    onSearch(value, inputType);
  }

  const handleEnter = e => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSubmit(e);
    }
  }

  const onChange = (event, { newValue }) => {
    setValue(newValue)
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    let newValue = getSuggestions(value, skuList)
    setSuggestions(newValue)
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([])
  };

  const inputProps = {
    placeholder: "Type Sku or Title",
    value,
    onChange: onChange
  };

  return (

        <div className={classes.searchBar}>
            <div
              className={classes.search}
              elevation={1}
            >
              <SearchIcon className={classes.searchIcon} />
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                theme={classes}
                onKeyDown={handleEnter}
              />

          </div>
          <div className={classes.date}>
            <div className={classes.dates}>
              {/*<div className={classes.padding20} >{`${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`}</div>*/}
              <Button
                  onClick={togglePanel}
              >

                <CalendarTodayIcon
                  size="large"
                  variant="contained"
                  color="primary"
                />
              </Button>
            </div>
            {openDiv ? (
                <Dialog
                  maxWidth='md'
                  open={openDiv}
                  onEscapeKeyDown={togglePanel}
                  onClose={togglePanel}
                  onBackdropClick={togglePanel}
                  aria-labelledby="Choose date"
                >
                  <OrdersDatePicker handleCalendarAccept={handleCalendarAccept} togglePanel={togglePanel} startDate={startDate} endDate={endDate}  />

                </Dialog>
              ) : null
            }
          </div>

          <Button
            onClick={handleSubmit}
            size="large"
            variant="contained"
            color="primary"
          >
            Search
          </Button>
        </div>

  );
};

SearchBar.propTypes = {
  className: PropTypes.string,
  onFilter: PropTypes.func,
  onSearch: PropTypes.func
};

export default SearchBar;
