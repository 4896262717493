import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { Typography, Avatar, colors, Dialog, Card, CardActionArea, CardMedia, CardActions, Button } from '@material-ui/core';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(2)
  },
  authUser: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& $body': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText
    }
  },
  inner: {
    display: 'flex',
    maxWidth: 500
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  body: {
    backgroundColor: colors.grey[100],
    color: theme.palette.text.primary,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2, 3)
  },
  content: {
    marginTop: theme.spacing(1)
  },
  image: {
    marginTop: theme.spacing(2),
    height: 'auto',
    width: 380,
    maxWidth: '100%',
    padding: theme.spacing(2),
    textAlign: "center"
  },
  footer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end'
  },
  dialog: {
    background: '#eeeeee',
    overflowY: 'scroll',
    '-webkit-overflow-scrolling': 'touch',
  },
  dialogContent: {
    minHeight: '800px'
  },
  user: {
    color: "#fff",
    marginBottom: "12px",
    fontWeight: 600
  },
  admin: {
    marginBottom: "12px",
    fontWeight: 600
  },
  chatText: {
    marginTop: "10px"
  },
  pdfIcon: {
    fontSize: "18em",
    color: "#C9C9C9",
    cursor: "pointer"
  },
  button: {
    color: "#4B7CEE"
  },
  actionArea: {
    textAlign: "center",
    justifyContent: "center",
    borderTop: "1px solid #eee",
    '& button': {
      fontSize: "1.1em",
      wordSpacing: "2px"
    }
  }
}));

const ConversationMessage = props => {
  const { public_comment, className, currentUserAvatar, ...rest } = props;
  const classes = useStyles();
  let [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
  }

  const handleClose = () => {
    setClicked(false);
  }


  return (
    <div
      {...rest}
      className={clsx(
        classes.root,
        {
          [classes.authUser]: public_comment.author_type === 'User'
        },
        className
      )}
    >
      {!public_comment.archived && <div className={classes.inner}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={public_comment.author_avatar?.url || require(`assets/img/avatars/00${public_comment.author_id % 46 + 1}.svg`)}
          to="/profile/1/timeline"
        />
        <div>
          <div className={classes.body}>
            <div>

              {public_comment.author_type === 'User' ? <Typography variant="h4" className={classes.user}>{public_comment.username} </Typography> :
                <Typography variant="h4" className={classes.admin}>{public_comment.username}</Typography>}

            </div>
            <div className={classes.content}>
              {public_comment.image.url ? (
                <>
                  {public_comment.image.url.includes(".pdf") ?
                    <Card>
                      <CardActionArea className={classes.image}>
                        <PictureAsPdfIcon className={classes.pdfIcon} />
                      </CardActionArea>
                      <CardActions className={classes.actionArea}>
                        <Button size="small" className={classes.button} href={`${public_comment.image.url}`} target="_blank"
                          startIcon={<VisibilityIcon />}>
                          VIEW </Button>

                      </CardActions>
                    </Card>
                    :
                    <Card>
                      <CardActionArea>

                        <CardMedia className={classes.image}
                          component="img"
                          alt="Attachment"
                          height="140"
                          image={public_comment.image.thumb.url || public_comment.image.url}
                          title="Attachment"
                        />
                      </CardActionArea>
                      <CardActions className={classes.actionArea}>
                        <Button size="small" className={classes.button} onClick={handleClick}
                          startIcon={<VisibilityIcon />}>
                          VIEW </Button>

                      </CardActions>
                    </Card>
                  }


                </>
              ) : null}
              <Typography
                color="inherit"
                variant="h4" className={classes.chatText}
              >
                {public_comment.body}
              </Typography>
            </div>
          </div>
          <div className={classes.footer}>
            <Typography
              className={classes.time}
              variant="h6"
            >
              {moment(public_comment.created_at).fromNow()}
            </Typography>
          </div>
        </div>
      </div>
      }
      <Dialog
        maxWidth='md'
        open={clicked}
        onEscapeKeyDown={handleClose}
        onClose={handleClose}
        onBackdropClick={handleClose}
      >
        <div className={classes.dialog}>
          <img
            alt="Attachment"
            className={classes.imageLarge}
            src={public_comment.image.url}
            onClick={handleClick}
          />
        </div>
      </Dialog>
    </div>
  );
};

ConversationMessage.propTypes = {
  className: PropTypes.string,
  public_comment: PropTypes.object.isRequired
};

export default ConversationMessage;
