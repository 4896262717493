import React from 'react'
import {withStyles, Tooltip} from '@material-ui/core';
import { TextValidator} from 'react-material-ui-form-validator';

const styles = theme => ({
  flexItem: {
    margin: '11px',
    width: '26%',
    textAlign: 'center'
  },
  gridItem: {
    paddingRight: '8px',
  },
  flexBox: {
    display: 'flex',
  },
  titleContainer: {
    width: '10%',
    position: 'relative'
  },
  rotatedTitle: {
    transform: 'rotate(-90deg)',
    transformOrigin: '0 0',
    position: 'absolute',
    bottom: 0,
    left: 0,
    maxWidth: 40,
    overflow: 'hidden'
  },
  footer: {
    marginTop: '50px',
    opacity: '0.8',
  }
})

function P2_Values(props) {
  const {classes, onNewFieldChange, onChange, parentState} = props;


  const fields =
    [
      {value: 'supplier_cost', label: 'Supplier Cost ($AUD)', helper: 'The supplier cost will be used for insurance claim.', required: ['required'], errorMessages: ['Value is required']},
      {value: 'retail_cost', label: 'Retail Cost ($AUD)', helper: 'The retail cost will be used on custom declaration.'},
      {value: 'low_stock_alert', label: 'Low stock alert', helper: 'Notifying you when inventory falls below a certain number'}
    ]


  let otherUnits

  if(parentState.units) {
    otherUnits =
      parentState.units.map((unit, index) =>
        <div className={classes.flexBox} key={index}>
          <div className={classes.titleContainer}>
            <div className={classes.rotatedTitle}>

              {unit['unit_type']}
            </div>
          </div>
          {fields.map((column, i) =>
            <Tooltip
              title={column.helper}
              disableFocusListener
            >
              <TextValidator
                key={`${column.value}_${i}`}
                className={classes.flexItem}
                id={column.value}
                name={column.value}
                fullWidth
                value={unit[column.value]}
                onChange={e => onNewFieldChange(e, index)}
                variant="outlined"
                validators={column.required}
                errorMessages={column.errorMessages}
                />
            </Tooltip>
          )}
        </div>
      )
  }


  return (
    <>
      <div className={classes.flexBox}>
        <div className={classes.titleContainer}></div>
        {fields.map((column) =>
          <div className={classes.flexItem} key={column.value}>{column.label}</div>
        )}
      </div>
      <div className={classes.flexBox}>
        <div className={classes.titleContainer}>
          <div className={classes.rotatedTitle}>
            Base
          </div>
        </div>
        {fields.map((column, i) =>
          <Tooltip
            title={column.helper}
            disableFocusListener
          >
            <TextValidator
              // style={ pointerEvents: "none" }
              key={`${column.value}_${i}`}
              className={classes.flexItem}
              id={column.value}
              name={column.value}
              fullWidth
              value={parentState[column.value]}
              onChange={onChange}
              variant="outlined"
              validators={column.required}
              errorMessages={column.errorMessages}
              />
          </Tooltip>
        )}
      </div>
      {otherUnits}

    </>
  )
}

export default withStyles(styles)(P2_Values);
