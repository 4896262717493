import React from 'react'
import { Grid, Fade } from '@material-ui/core';
import SpaceGridItem from './SpaceGridItem';

import { reverseUserRoute } from 'routes';


class SpaceGrid extends React.PureComponent {
    render() {
        const spaceUrl = reverseUserRoute('Space')
        return (
            <div>
                <Grid
                    container
                    spacing={4}
                    justify='center'
                    wrap='wrap'
                    alignItems="flex-start"
                    style={{
                        marginTop: 24,
                    }}
                >
                    {this.props.itemsSearch.map(item => (
                        <Fade in timeout={300} key={item.id}>
                            <SpaceGridItem item={item} key={item.id} link={`${spaceUrl}/${item.id}`} color={this.props.mapColor(item.category)}/>
                        </Fade>

                    ))}
                </Grid>
            </div>
        )
    }
}

export default SpaceGrid
