/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react';
import { colors } from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import AppsIcon from '@material-ui/icons/Apps';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import FolderSharedIcon from '@material-ui/icons/FolderSharedOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';

import { Label } from 'components';
import { reverseUserRoute } from 'routes';
import {UID} from 'AppConstants'; 

export default [
  {
    title: 'Pages',
    pages: [
      {
        title: 'Overview',
        href: '/user/'+UID+'/overview/',
        icon: HomeIcon
      },
      {
        title: 'Space',
        href: '/user/'+UID+'/',
        icon: AppsIcon,
        children: [
          {
            title: 'Products',
            // href: '/user/'+UID+'/space/products/'
            href: () => reverseUserRoute('Space')
          },
          {
            title: 'Bundles',
            href: () => reverseUserRoute('Bundles')
          },
          {
            title: 'Categories',
            href: () => reverseUserRoute('Categories')
          }
        ]
      },
      {
        title: 'Orders',
        href: '/user/'+UID+'/orders',
        icon: ListAltIcon,
        children: [
          {
            title: 'Summary',
            href: '/user/'+UID+'/orders/summary/'
          },
          {
            title: 'Terminologies',
            href: '/user/'+UID+'/terminologies/'
          }
        ]
      },
      {
        title: 'Analytics',
        href: '/analytics',
        value: 'analytics',
        icon: BarChartIcon,
        // label: () => <Label color={colors.green[500]}>New</Label>,
        children: [
          {
            title: 'SKU Breakdown',
            href: '/user/'+UID+'/analytics/sku_breakdown/',
          },
          // {
          //   title: 'Shipping',
          //   href: '/management/customers/1/summary/',
          // }
        ]
      },
      {
        title: 'Manage',
        href: '/manage',
        icon: FolderSharedIcon,
        children: [
          {
            title: 'Suppliers',
            href: '/user/'+UID+'/manage/suppliers/'
          },
          {
            title: 'Customers',
            href: '/user/'+UID+'/manage/customers/'
          },
          // {
          //   title: 'Team',
          //   href: '/user/'+UID+'/manage/team/'
          // }
        ]
      },
      {
        title: 'Chat',
        href: '/user/'+UID+'/chat/',
        icon: ChatIcon,
        // label: () => (
        //   <Label
        //     color={colors.red[500]}
        //     shape="rounded"
        //   >
        //     4
        //   </Label>
        // )
      },
      // {
      //   title: 'Profile',
      //   href: '/profile',
      //   icon: PersonIcon,
      //   children: [
      //     {
      //       title: 'Timeline',
      //       href: '/profile/1/timeline'
      //     },
      //     {
      //       title: 'Connections',
      //       href: '/profile/1/connections'
      //     },
      //     {
      //       title: 'Projects',
      //       href: '/profile/1/projects'
      //     },
      //     {
      //       title: 'Reviews',
      //       href: '/profile/1/reviews'
      //     }
      //   ]
      // },
      {
        title: 'Invoices',
        value: 'invoices',
        href: '/user/'+UID+'/invoices/',
        icon: ReceiptIcon
      },
      // {
      //   title: 'Kanban Board',
      //   href: '/kanban-board',
      //   icon: ListAltIcon
      // },
      // {
      //   title: 'Mail',
      //   href: '/mail',
      //   icon: MailIcon,
      //   label: () => (
      //     <Label
      //       color={colors.red[500]}
      //       shape="rounded"
      //     >
      //       2
      //     </Label>
      //   )
      // },
      // {
      //   title: 'Chat',
      //   href: '/chat',
      //   icon: ChatIcon,
      //   label: () => (
      //     <Label
      //       color={colors.red[500]}
      //       shape="rounded"
      //     >
      //       4
      //     </Label>
      //   )
      // },
      // {
      //   title: 'Calendar',
      //   href: '/calendar',
      //   icon: CalendarTodayIcon,
      //   label: () => <Label color={colors.green[500]}>New</Label>
      // },
      // {
      //   title: 'Settings',
      //   href: '/settings',
      //   icon: SettingsIcon,
      //   children: [
      //     {
      //       title: 'General',
      //       href: '/settings/general'
      //     },
      //     {
      //       title: 'Subscription',
      //       href: '/settings/subscription'
      //     },
      //     {
      //       title: 'Notifications',
      //       href: '/settings/notifications'
      //     },
      //     {
      //       title: 'Security',
      //       href: '/settings/security'
      //     }
      //   ]
      // },
      // {
      //   title: 'Authentication',
      //   href: '/auth',
      //   icon: LockOpenIcon,
      //   children: [
      //     {
      //       title: 'Login',
      //       href: '/auth/login'
      //     },
      //     {
      //       title: 'Register',
      //       href: '/auth/register'
      //     }
      //   ]
      // },
      // {
      //   title: 'Errors',
      //   href: '/errors',
      //   icon: ErrorIcon,
      //   children: [
      //     {
      //       title: 'Error 401',
      //       href: '/errors/error-401'
      //     },
      //     {
      //       title: 'Error 404',
      //       href: '/errors/error-404'
      //     },
      //     {
      //       title: 'Error 500',
      //       href: '/errors/error-500'
      //     }
      //   ]
      // }
    ]
  },
  {
    title: 'Support',
    pages: [
      // {
      //   title: 'Presentation',
      //   href: '/presentation',
      //   icon: PresentToAllIcon
      // },
      {
        title: 'Getting started',
        href: '/user/getting-started',
        icon: CodeIcon
      },
      {
        title: 'Changelog',
        href: '/user/changelog',
        icon: ViewModuleIcon,
        label: () => <Label color={colors.blue['500']}>v3.3</Label>
      }
    ]
  }
];
