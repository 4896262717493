import React, { Component } from 'react';

import { withStyles } from '@material-ui/core';
import SuppliersTable from 'components/Table/SuppliersTable';
import Header from 'components/Header/Header';
import AddNew from 'components/Help/AddNew';
import AddButton from 'components/Button/AddButton';
import {UID} from 'AppConstants';

class SuppliersIndex extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }

  handleCellClick = (data) => {
    this.props.history.push(`/user/${UID}/manage/suppliers/${data[0]}`);
  };


  render() {
    const {classes, permittedToAccess} = this.props;

      return (
        <div className={classes.root}>
          <Header title="Manage" subtitle="Suppliers"/>
          <AddButton text='Add Supplier' link={`/user/${UID}/manage/suppliers/add`} tooltip='Create Supplier' disabled={!permittedToAccess("suppliers:create")}/>

          { this.props.suppliers.length === 0 ? (
              <AddNew title="supplier" />
            ):( <SuppliersTable suppliers={this.props.suppliers} />
          )}
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
  }
})

export default withStyles(styles)(SuppliersIndex);
