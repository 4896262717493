import React from 'react';
import PropTypes from 'prop-types';

import { Tooltip, IconButton } from '@material-ui/core';

import SyncAlt from '@material-ui/icons/SyncAlt';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { UID } from 'AppConstants';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  paraHeight: {
    lineHeight: '51px !important'
  }
});

function InventoryTable(props) {
	const { item_details, handleAlertOpen, array, message, ...rest } = props;
	const classes = { ...rest.classes };

	const subtotal = (units, itemsPerUnit) => (itemsPerUnit || 1) * (units || 0);

	const total = (array, value) => {
		let initialValue = 0;
		let sum = array.reduce(function(accumulator, currentValue) {
			return (
				accumulator + subtotal(currentValue[value], currentValue.items_per_unit)
			);
		}, initialValue);
		return sum;
	};

  const breakDownMessage = (type, warehouse_name) => {
    const message = `Breakdown to ${!!type ? type.unit_type : 'BASE'} for ${warehouse_name}`
    return message
  }

	return (
		<div style={{ width: '100%', overflow: 'scroll' }}>
			<table className={classes.totalTable}>
				<thead>
					<tr>
						<Tooltip title='Unique Sku identificator'>
							<th>SKU</th>
						</Tooltip>
						<Tooltip title='Number of base items in this unit type'>
							<th>Per Unit</th>
						</Tooltip>
						<Tooltip title='KeepSpace Warehouse Location for Inventory'>
							<th>Warehouse</th>
						</Tooltip>
						<Tooltip title='Physically in the warehouse'>
							<th>In Storage</th>
						</Tooltip>
						<Tooltip title='Quantity in storage minus reserved for open orderers'>
							<th>Available</th>
						</Tooltip>
						<Tooltip title='Total of base items In Storage'>
							<th>Base In St.</th>
						</Tooltip>
						<Tooltip title='Total of base items In Available'>
							<th>Base Avail</th>
						</Tooltip>

						<Tooltip title='Break unit into smaller units'>
							<th>Break Unit</th>
						</Tooltip>
					</tr>
				</thead>
				<tbody className={classes.footer}>
					<tr style={{ fontWeight: 'bold' }}>
						<td className={classes.totalTableTd}>
							<Typography>{item_details.sku}</Typography>
							<Tooltip title='Type of the unit, eg Base, Box, Palette'>
								<Typography variant={'subtitle2'} className ={ classes.paraHeight}>
									{item_details.unit_type || 'Base'}
								</Typography>
							</Tooltip>
						</td>
						<td className={classes.totalTableTd}>
							{item_details.items_per_unit || '1'}
						</td>

						<td className={classes.totalTableTd}>
							{!!item_details?.warehouse_stock_mapping &&
								item_details?.warehouse_stock_mapping?.map((row) => (
									<Typography className ={ classes.paraHeight}>{row.warehouse_name || 'Perth'}</Typography>
								))}
						</td>
						<td className={classes.totalTableTd}>
							{!!item_details?.warehouse_stock_mapping &&
								item_details?.warehouse_stock_mapping?.map((row) => (
									<Typography className ={ classes.paraHeight}>{row.quantity_stored || '0'}</Typography>
								))}
						</td>
						<td className={classes.totalTableTd}>
							{!!item_details?.warehouse_stock_mapping &&
								item_details?.warehouse_stock_mapping?.map((row) => (
									<Typography className ={ classes.paraHeight}>{row.quantity_available || '0'}</Typography>
								))}
						</td>
						<td className={classes.totalTableTd}>
							{!!item_details?.warehouse_stock_mapping &&
								item_details?.warehouse_stock_mapping?.map((row) => (
									<Typography className ={ classes.paraHeight}>
										{subtotal(row.quantity_stored, row.items_per_unit)}
									</Typography>
								))}
						</td>

						<td className={classes.totalTableTd}>
							{!!item_details?.warehouse_stock_mapping &&
								item_details?.warehouse_stock_mapping?.map((row) => (
									<Typography className ={ classes.paraHeight}>
										{subtotal(row.quantity_available, row.items_per_unit)}
									</Typography>
								))}
						</td>

							<td className={classes.totalTableTd}>
								{!!item_details?.warehouse_stock_mapping &&
									item_details?.warehouse_stock_mapping?.map((row) => (
                    <Tooltip title={
                      item_details.parent_id && row.quantity_available
                        ? breakDownMessage(item_details, row.warehouse_name)
                        : "This unit type can't be broken into smaller"
                    }>
										<Typography>
											<IconButton
												disabled={
													item_details.parent_id === null ||
													row.quantity_available === 0
												}
												onClick={() => handleAlertOpen(item_details, row.warehouse_id)}
											>
												<VerticalAlignBottomIcon />
											</IconButton>
										</Typography>
                    </Tooltip>
									))}
							</td>
					</tr>

					{Boolean(item_details) && item_details.other_packaging_types
						? item_details.other_packaging_types.map((packaging) => (
								<tr key={packaging.id}>
									<td className={classes.totalTableTd}>
										<a href={`/user/${UID}/space/products/${packaging.id}`}>
											{packaging.sku}
										</a>
										<Tooltip title='Type of the unit, eg Base, Box, Palette'>
											<Typography variant={'subtitle2'} className ={ classes.paraHeight}>
												{packaging.unit_type || 'Base'}
											</Typography>
										</Tooltip>
									</td>
									<td className={classes.totalTableTd}>
										{packaging.items_per_unit || '1'}
									</td>
									<td>
										{!!packaging?.warehouse_stock_mapping &&
											packaging?.warehouse_stock_mapping?.map((row) => (
												<Typography className ={ classes.paraHeight}>{row.warehouse_name || 'Perth'}</Typography>
											))}
									</td>
									<td className={classes.totalTableTd}>
										{!!packaging?.warehouse_stock_mapping &&
											packaging?.warehouse_stock_mapping?.map((row) => (
												<Typography className ={ classes.paraHeight}>{row.quantity_stored || '0'}</Typography>
											))}
									</td>
									<td className={classes.totalTableTd}>
										{!!packaging?.warehouse_stock_mapping &&
											packaging?.warehouse_stock_mapping?.map((row) => (
												<Typography className ={ classes.paraHeight}>{row.quantity_available || '0'}</Typography>
											))}
									</td>
									<td className={classes.totalTableTd}>
										{!!packaging?.warehouse_stock_mapping &&
											packaging?.warehouse_stock_mapping?.map((row) => (
												<Typography className ={ classes.paraHeight}>
													{subtotal(row.quantity_stored, row.items_per_unit)}
												</Typography>
											))}
									</td>
									<td className={classes.totalTableTd}>
										{!!packaging?.warehouse_stock_mapping &&
											packaging?.warehouse_stock_mapping?.map((row) => (
												<Typography className ={ classes.paraHeight}>
													{subtotal(row.quantity_available, row.items_per_unit)}
												</Typography>
											))}
									</td>
										<td className={classes.totalTableTd}>
                      {!!packaging?.warehouse_stock_mapping &&
                        packaging?.warehouse_stock_mapping?.map((row) => (
                          <Tooltip title={
                            packaging.parent_id && row.quantity_available
                              ? breakDownMessage(packaging, row.warehouse_name)
                              : "This unit type can't be broken into smaller"
                          }>
                          <Typography>
                            <IconButton
                              disabled={
                                packaging.parent_id === null ||
													      row.quantity_available === 0
                              }
                              onClick={() => handleAlertOpen(packaging, row.warehouse_id)}
                            >
                              <VerticalAlignBottomIcon />
                            </IconButton>
                          </Typography>
                          </Tooltip>
                        ))}
										</td>
								</tr>
						  ))
						: null}
				</tbody>
				{array.length > 1 ? (
					<tfoot>
						<tr>
							<th id='total' colSpan='4'></th>
							<th className={classes.footer}>Total:</th>
							<td className={classes.footer}>
								{total(array, 'quantity_in_storage')}
							</td>
							<td className={classes.footer}>
								{total(array, 'quantity_available')}
							</td>
						</tr>
					</tfoot>
				) : null}
			</table>
		</div>
	);
}

export default withStyles(styles)(InventoryTable);