import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { deleteSupplier } from 'actions/supplierActions';
import clsx from 'clsx';
import useRouter from 'utils/useRouter';

import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmAlert from 'components/Help/ConfirmAlert';
import {UID} from 'AppConstants';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  actions: {
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const SupplierCard = props => {
  const { title, supplier, rows, className, handleEditOpen, edit, prefixes=[], permittedToAccess, ...rest } = props;

  const classes = useStyles();
  const [openAlert, setOpenAlert] = useState(false);
  const dispatch = useDispatch();
  const { history } = useRouter();

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleDelete = e => {
    dispatch(deleteSupplier(supplier.id))
    .then(result => {
      if (result) {
          // result is null when in error
          history.push(`/user/${UID}/manage/suppliers`);
      }
    })

    handleAlertClose()
  }

  function humanize(str) {
    return str.trim().split(/\s+/).map(function(str) {
      return str.replace(/_/g, ' ').replace(/\s+/, ' ').trim();
    }).join(' ').toLowerCase().replace(/^./, function(m) {
      return m.toUpperCase();
    });
  }

  let editAction
  if (edit && permittedToAccess("suppliers:edit")) {
     editAction =
      <CardActions className={classes.actions}>
        <Button onClick={handleEditOpen}>
          <EditIcon className={classes.buttonIcon} />
          Edit
        </Button>
        <Button onClick= {() => setOpenAlert(true)} >
          <DeleteIcon className={classes.buttonIcon} />
          Delete
        </Button>
      </CardActions>
  }

  return (

    <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <CardHeader title={title} />
          <Divider />
          <CardContent className={classes.content}>
            <Table>
              <TableBody>
                {rows.map((row, i) =>
                  <TableRow key={row}>
                    <TableCell>{humanize(row)}</TableCell>
                    <TableCell>{`${prefixes.length > 0 ? prefixes[i] : ''} ${supplier[row]}`}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
          {editAction}
          <ConfirmAlert
            open = {openAlert}
            description  = "Do you really want to delete this supplier?"
            onSubmit = {handleDelete}
            onClose = {handleAlertClose}
          />
    </Card>
  );
};

SupplierCard.propTypes = {
  className: PropTypes.string,
  supplier: PropTypes.object.isRequired
};

export default SupplierCard;
