import {
    FETCH_INVOICES_BEGIN,
    FETCH_INVOICES_SUCCESS,
    FETCH_INVOICES_FAILURE,
} from 'actions/invoiceActions';

const initialState = {
    invoices: [],
    loading: false,
    error: null,
}

export default function invoicesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_INVOICES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case FETCH_INVOICES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                invoices: action.payload.invoices,
            }
        case FETCH_INVOICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                invoices: [],
            }
        default:
            return state
    }
}