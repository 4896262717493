import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { Page } from 'components';
import { Overview } from './components';
import Header from 'components/Header/HeaderDetailsPage';
import { fetchSupplierByID } from 'actions/supplierActions';
import {UID} from 'AppConstants';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3),
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(3)
  }
}));

const SuppliersDetails = props => {
  const { match, history, countries, error, permittedToAccess } = props;
  const classes = useStyles();
  const { id, tab } = match.params;
  const dispatch = useDispatch();

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  useEffect(() => {
    dispatch(fetchSupplierByID(id))
  }, [dispatch, id]);

  let supplier = useSelector(state =>  state.suppliersReducer.supplier)

  if (!supplier) {
    return null;
  }

  const tabs = [
    { value: 'overview', label: 'Overview', disabled: false },
    { value: 'skus', label: 'Skus', disabled: true },
    { value: 'activity', label: 'Activity', disabled: true }
  ];

  if (!tab) {
    return <Redirect to={`/user/${UID}/manage/suppliers/${id}/overview`} />;
  }

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  return (
    <Page
      className={classes.root}
      title="Suppplier Details"
    >
      <Header menu="Suppliers" title={supplier.name} path='Suppliers:Index'/>
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable"
      >
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            label={tab.label}
            value={tab.value}
            disabled={tab.disabled}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'overview' && <Overview id={id} supplier={supplier} countries={countries} error={error}  permittedToAccess={permittedToAccess}/>}
        {tab === 'skus' && <Overview id={id} supplier={supplier}/>}
        {tab === 'activity' && <Overview id={id} supplier={supplier}/>}
      </div>
    </Page>
  );
};

SuppliersDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default SuppliersDetails;
