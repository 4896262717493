import {
    FETCH_BUNDLES_BEGIN,
    FETCH_BUNDLES_SUCCESS,
    FETCH_BUNDLES_FAILURE,
    CREATE_BUNDLE_BEGIN,
    CREATE_BUNDLE_FAILURE,
    CREATE_BUNDLE_SUCCESS,
    FETCH_BUNDLE_COMPONENTS_BEGIN,
    FETCH_BUNDLE_COMPONENTS_SUCCESS,
    FETCH_BUNDLE_COMPONENTS_FAILURE,
    FETCH_BUNDLE_HISTORY_BEGIN,
    FETCH_BUNDLE_HISTORY_SUCCESS,
    FETCH_BUNDLE_HISTORY_FAILURE,
    SET_SEARCH_TERM
} from 'actions/bundleActions';

const initialState = {
    bundles: [],
    loading: false,
    creating: false,
    fetchingComponents: false,
    error: null,
    searchTerm: '',
    searchActive: false,
    newBundleErrors: null,
    components: [],
    bundleHistory: []
}

export default function bundlesReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_BUNDLES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                bundles: [],
            };

        case FETCH_BUNDLES_SUCCESS:
            return {
                ...state,
                loading: false,
                bundles: action.payload.bundles,
            };

        case FETCH_BUNDLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                bundles: []
            };

        case CREATE_BUNDLE_BEGIN:
            return {
                ...state,
                creating: true
            }

        case CREATE_BUNDLE_SUCCESS: {
            return {
                ...state,
                error: null,
                creating: false,
                bundles: [action.payload.bundle, ...state.bundles]
            }
        }

        case CREATE_BUNDLE_FAILURE:
            let responseData
            let otherError

            if (!action.payload.error.response) {
                // No response: possible network error
                otherError = { response: { statusText: action.payload.error.message || 'Error' } }
            } else if (action.payload.error.response && action.payload.error.response.status === 422) {
                // Unprocessable Entity; It's likely that the response contains fields with errors
                responseData = {
                    ...action.payload.error.response.data
                }
            } else {
                otherError = action.payload.error
            }

            return {
                ...state,
                creating: false,
                error: otherError,
                newBundleErrors: responseData,
            }

        case FETCH_BUNDLE_COMPONENTS_BEGIN:
            return {
                ...state,
                fetchingComponents: true,
                error: null
            };

        case FETCH_BUNDLE_COMPONENTS_SUCCESS:

            let componentsObject = action.payload.components.map(a => (
                {
                    sku: a[0],
                    title: a[1],
                    quantity: a[2],
                    quantity_available: a[3]
                }
            ))
            return {
                ...state,
                fetchingComponents: false,
                components: componentsObject,
                error: null
            };

        case FETCH_BUNDLE_COMPONENTS_FAILURE:
            return {
                ...state,
                fetchingComponents: false,
                error: action.payload.error,
                components: []
            };


        case FETCH_BUNDLE_HISTORY_BEGIN:
            return {
                ...state,
                fetchingHistory: true,
                error: null
            };

        case FETCH_BUNDLE_HISTORY_SUCCESS:

            let bundleHistoryObject = action.payload.history.map(a => (
                {
                    orderId: a[0],
                    orderSource: a[1],
                    createdAt: a[2],
                    bundleQuantity: a[3]
                }
            ))

            return {
                ...state,
                fetchingHistory: false,
                bundleHistory: bundleHistoryObject,
                error: false
            };

        case FETCH_BUNDLE_HISTORY_FAILURE:
            return {
                ...state,
                fetchingComponents: false,
                error: action.payload.error,
                bundleHistory: []
            };

        case SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.term
            }

        default:
            return state
    }
}
