import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withStyles,
        Paper,
        Button,
        Table,
        TableBody,
        TableCell,
        TableHead,
        TableRow,
      } from '@material-ui/core';
import { connect } from 'react-redux';
import { deleteCategory } from 'actions/categoryActions';
import Header from 'components/Header/Header';
import AddNew from 'components/Help/AddNew';
import AddButton from 'components/Button/AddButton';
import ConfirmAlert from 'components/Help/ConfirmAlert';
import {UID} from 'AppConstants';


const styles = theme => ({
  root: {
    width: '100%',
    margin: 'auto',
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    minHeight: '200px',
    [theme.breakpoints.down("sm")]: {
        width: '95%',
    },
  },
  paper: {
    width: '100%',
    overflowX: 'auto',
    marginTop: '24px'
  },
  table: {
    // minWidth: 450,
  },
  categoryColour: {
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    margin: 'auto'
  },
})


class CategoriesIndex extends Component {
  constructor(props){
        super(props);
        this.state = {
          openAlert: false,
          deleteCategoryId: null
        }
        this.deleteCategory = this.deleteCategory.bind(this)
    }

  handleCellClick = (data) => {
    this.props.history.push(`/user/${UID}/manage/categories/${data[0]}`);
  };

  deleteCategory = (e) => {
    e.preventDefault();

    this.props.dispatch(deleteCategory(this.state.deleteCategoryId))
    this.handleAlertClose()
  };

  handleAlertClose = () => {
    this.setState({openAlert: false});
  };

  handleClick = (id) => {
    this.setState({openAlert: true});
    this.setState({deleteCategoryId: id});
  }

  render() {
    const {classes} = this.props;

    const subcategoryRow = (category) => {
      const thisSubcategories = this.props.subcategories.filter(subcategory => subcategory.parent_id === category.id);
        if (thisSubcategories != null )  {
          return (
            <>
              { thisSubcategories.map(subcategory => (
                  <TableRow key={subcategory.id}>
                    <TableCell style={{paddingLeft:'50px'}} align="left">{subcategory.title}</TableCell>
                    <TableCell align="center">
                      <div className={classes.categoryColour} style={{background: subcategory.colour}}>
                    </div>
                    </TableCell>
                    <TableCell align="right" >
                      <Button>
                        <Link to={`/user/${UID}/space/categories/${subcategory.id}/edit/`}>Edit</Link>
                      </Button>
                      <Button  onClick={() => this.handleClick(subcategory.id)} >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
              ))}
            </>
          )
        }
    }

    const categoryRow = this.props.categories.map((category, index) => (
      <>
        <TableRow key={category.id}>
          <TableCell align="left">{category.title}</TableCell>
          <TableCell align="center">
            <div className={classes.categoryColour} style={{background: category.colour}}>
          </div>
          </TableCell>
          {this.props.permittedToAccess("categories:edit") ?
            <TableCell align="right" >
              <Button>
                <Link to={`/user/${UID}/space/categories/${category.id}/edit/`}>Edit</Link>
              </Button>
              <Button onClick={() => this.handleClick(category.id)} >
                Delete
              </Button>
            </TableCell> : null
          }
        </TableRow>
        {subcategoryRow(category)}
      </>
    ))

    return (
        <div className={classes.root}>
         <ConfirmAlert
            open = {this.state.openAlert}
            description  = "Do you really want to delete this category?"
            onSubmit = {(e) => this.deleteCategory(e)}
            onClose = {this.handleAlertClose}
          />
          <Header title="Space" subtitle="Categories"/>
          <AddButton text='Add Category' link={`/user/${UID}/space/categories/add`} tooltip='Create Category' disabled={!this.props.permittedToAccess("categories:create")} />
          { this.props.categories.length === 0 ? (
            <AddNew title="category"/>
          ):(
            <Paper className={classes.paper}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Title</TableCell>
                    <TableCell align="center">Colour</TableCell>
                    {this.props.permittedToAccess("categories:edit") ?
                      <TableCell align="right">Actions</TableCell> : null
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                {categoryRow}
                </TableBody>
              </Table>
            </Paper>
          )}
        </div>
      );
  }
}

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    // categories: [],
  categories: state.categoriesReducer.categories.filter(category => category.parent_id === null),
  loading: state.categoriesReducer.loading,
  error: state.categoriesReducer.error,
  subcategories: state.categoriesReducer.categories.filter(category => category.parent_id !== null)
});

export default connect(mapStateToProps)(withStyles(styles)(CategoriesIndex));
