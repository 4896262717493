import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {useSelector } from 'react-redux';

import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import {UID} from 'AppConstants';

import { withStyles } from '@material-ui/core/styles';
import SKUEditForm from 'components/FormsPleaseStopDoingThis/SKUForm/SKUEditForm';

function ResponsiveDialog(props) {

    const [open, setOpen] = useState(true)
    const { classes, permittedToAccess } = props;
    const id = parseInt(props.match.params.id);

   const item = useSelector(state => state.spaceReducer.fetchedItem);

    function handleClose(){
        setOpen(false);
        setTimeout(() => {
            props.history.push(`/user/${UID}/space/products`);
        }, 100);
    }

    if (!permittedToAccess("products:edit")) props.history.push(`/user/${UID}/space/products`)

    return (
        <Dialog
            fullScreen={props.fullScreen}
            maxWidth='lg'
            fullWidth={true}
            open={open}
            // onEscapeKeyDown={handleClose}
            // onClose={handleClose}
            // onBackdropClick={handleClose}
            aria-labelledby="responsive-dialog-title"
            overflow="visible"
        >
            <div className={classes.dialog}>
              <SKUEditForm skuEdit={item} handleClose={handleClose} countries={props.countries}/>
            </div>
        </Dialog>
    );
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const styles = theme => ({
    root: {
        minHeight: '400px',
        flexWrap: 'wrap'
    },
    dialog: {
        background: '#eeeeee',
        // overflowY: 'scroll',
        // '-webkit-overflow-scrolling': 'touch',
    },
    paper: {
        minHeight: 400,
    },
    img: {
        borderRadius: '50%',
        height: 250,
        width: 250,
        zIndex: 1041
    },
    card: {
        padding: '100px',
        paddingTop: '20px',
        position: 'relative',
    },

});

export default withMobileDialog({
    breakpoint: 'sm',
  })(withStyles(styles)(ResponsiveDialog));

