import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from '@material-ui/core';
import {UID} from 'AppConstants';

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 700,
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      }
    }
  });

const Headers = ['Company', 'Display Name','Name', 'Country', 'Orders','Costs','Actions'];

export function SuppliersTable (props) {

  const { classes, suppliers } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value === 'All' ? suppliers.length : event.target.value);
    setPage(0)
  };

    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
                {Headers.map(header => (
                    <TableCell key={header}>{header}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliers.slice(page*rowsPerPage, (page+1)*rowsPerPage).map(supplier => (
              <TableRow
                key={supplier.id}
                hover
              >
                <TableCell>{supplier.name || "-"}</TableCell>
                <TableCell>{supplier.display_name || "-"}</TableCell>
                <TableCell> {supplier.contact_name || "-"} </TableCell>
                <TableCell>{supplier.country || "-"}</TableCell>
                <TableCell>{supplier.orders || "-"}</TableCell>
                <TableCell>$ {supplier.costs}</TableCell>
                <TableCell>
                  <Link to={`/user/${UID}/manage/suppliers/${supplier.id}`} >
                    View
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={suppliers.length}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 'All']}
        />
      </Paper>
    );
}

SuppliersTable.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default memo(withStyles(styles)(SuppliersTable));
