import keepspaceApi from 'services/keepspaceApi';

export const FETCH_INVOICES_BEGIN = 'FETCH_INVOICES_BEGIN';
export const FETCH_INVOICES_SUCCESS = 'FETCH_INVOICES_SUCCESS';
export const FETCH_INVOICES_FAILURE = 'FETCH_INVOICES_FAILURE'

export function fetchInvoices() {
    return dispatch => {
        dispatch(fetchInvoicesBegin());
        return keepspaceApi.getInvoices()
            .then(json => dispatch(fetchInvoicesSuccess(json)))
            .catch(error => dispatch(fetchInvoicesFailure(error)));
    };
}

export const fetchInvoicesBegin = () => ({
    type: FETCH_INVOICES_BEGIN
});

export const fetchInvoicesSuccess = invoices => ({
    type: FETCH_INVOICES_SUCCESS,
    payload: { invoices }
});

export const fetchInvoicesFailure = error => ({
    type: FETCH_INVOICES_FAILURE,
    payload: { error }
});