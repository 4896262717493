import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { fetchBundles, fetchBundleHistory } from 'actions/bundleActions';

import { Tabs, Tab, Divider, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Page } from 'components';
import { Details } from './Details';
import BundleTable from 'components/Table/BundleTable';
import Header from 'components/Header/HeaderDetailsPage';
import {UID} from 'AppConstants';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: theme.spacing(1)
    }
  },
  tabs: {
    marginTop: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
    }
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  content: {
    // marginBottom: theme.spacing(6),
    marginTop: theme.spacing(3)
  }
}));

const BundlesDetails = props => {
  const { match, history } = props;
  const classes = useStyles();
  const { id, tab } = match.params;
  const dispatch = useDispatch();
  let bundles = useSelector(state => state.bundlesReducer.bundles)
  const [bundle, setBundle] = useState({});

  let components = bundle.components
  let bundleHistory = useSelector(state => state.bundlesReducer.bundleHistory)

  const url = (row) => `${'/user/'}${UID}${'/orders/summary'}/${row.orderId}/details`

  const handleTabsChange = (event, value) => {
    history.push(value);
  };

  useEffect(() => {
    if (bundles.length === 0) {
      dispatch(fetchBundles())
    }
  }, [bundles, dispatch]);

  useEffect(() => {
    let bundleById = bundles.filter((bundle) => (parseInt(bundle.id) === parseInt(id)))[0]
    setBundle(bundleById)
  }, [bundles]);

  useEffect(() => {
    if (bundle.id) {
      dispatch(fetchBundleHistory(bundle.id))
    }
  }, [bundle.id, dispatch]);

  if (!tab) {
    return <Redirect to={`/user/${UID}/space/bundles/${id}/details`} />;
  }

  if (Object.keys(bundle).length === 0) {
    return null;
  }

  const tabs = [
    { value: 'details', label: 'Details' },
    { value: 'orderLog', label: 'Order Log'},
  ];

  if (!tabs.find(t => t.value === tab)) {
    return <Redirect to="/errors/error-404" />;
  }

  const columns = [
    { label: 'Order', tooltip: '', value: 'orderId' },
    { label: 'Source', tooltip: '', value: 'orderSource' },
    { label: 'Created', tooltip: '', value: 'createdAt' },
    { label: 'Quantity', tooltip: '', value: 'bundleQuantity' },
  ]

  return (
    <Page
      className={classes.root}
      title="Bundle Details"
    >
      <Header menu="Space/Bundles" title={`Bundle ${bundle.id}`} path='Bundles'/>
      <Tabs
        className={classes.tabs}
        onChange={handleTabsChange}
        scrollButtons="auto"
        value={tab}
        variant="scrollable"
      >
        {tabs.map(tab => (
          <Tab
            key={tab.value}
            label={tab.value}
            value={tab.value}
          />
        ))}
      </Tabs>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        {tab === 'details' && <Details bundle={bundle} components={components}/>}
        {tab === 'orderLog' && <BundleTable bundles={bundleHistory} columns={columns} url={url}/>}
      </div>
    </Page>
  );
};

BundlesDetails.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default BundlesDetails;
