import { apiPath, authHeaders } from 'AppConstants';
import keepspaceApi from 'services/keepspaceApi';
import { apiErrorHandler } from 'utils/apiErrorHandler';

export const FETCH_ITEMS_BEGIN = 'FETCH_ITEMS_BEGIN';
export const FETCH_ITEMS_SUCCESS = 'FETCH_ITEMS_SUCCESS';
export const FETCH_ITEMS_FAILURE = 'FETCH_ITEMS_FAILURE'

export const SEARCH_ITEMS_BEGIN = 'SEARCH_ITEMS_BEGIN'
export const SEARCH_ITEMS_SUCCESS = 'SEARCH_ITEMS_SUCCESS'
export const SEARCH_ITEMS_FAILURE = 'SEARCH_ITEMS_FAILURE'

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'
export const SET_SEARCH_ACTIVE = 'SET_SEARCH_ACTIVE'

export const UPDATE_PAGE_NUMBER = 'UPDATE_PAGE_NUMBER'
export const UPDATE_PER_PAGE_COUNT = 'UPDATE_PER_PAGE_COUNT'
// export const ARCHIVE_ITEM_BEGIN = 'ARCHIVE_ITEM_BEGIN';
// export const ARCHIVE_ITEM_SUCCESS = 'ARCHIVE_ITEM_SUCCESS';
// export const ARCHIVE_ITEM_FAILURE = 'ARCHIVE_ITEM_FAILURE';

export const CREATE_ITEM_BEGIN = 'CREATE_ITEM_BEGIN';
export const CREATE_ITEM_SUCCESS = 'CREATE_ITEM_SUCCESS';
export const CREATE_ITEM_FAILURE = 'CREATE_ITEM_FAILURE'

export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS'

export const UPDATE_ITEM_BEGIN = 'UPDATE_ITEM_BEGIN';
export const UPDATE_ITEM_SUCCESS = 'UPDATE_ITEM_SUCCESS';
export const UPDATE_ITEM_FAILURE = 'UPDATE_ITEM_FAILURE'
export const SET_MULTIPLE_SKU_ERROR = 'SET_MULTIPLE_SKU_ERROR'
export const BREAK_UNIT = 'BREAK_UNIT'

export const SEARCH_ORDER_BY_SKU_SUCCESS = 'SEARCH_ORDER_BY_SKU_SUCCESS';
export const SEARCH_ORDER_BY_SKU_FAILURE = 'SEARCH_ORDER_BY_SKU_FAILURE';
export const UPDATE_ARCHIVED_FILTER = 'UPDATE_ARCHIVED_FILTER';
export const UPDATE_AVAILABLE_FILTER = 'UPDATE_AVAILABLE_FILTER';
export const UPDATE_LOW_STOCK_FILTER = 'UPDATE_LOW_STOCK_FILTER';
export const UPDATE_CATEGORY_SELECTION ='UPDATE_CATEGORY_SELECTION';


export const FETCH_ITEM_BY_ID_BEGIN = 'FETCH_ITEM_BY_ID_BEGIN';
export const FETCH_ITEM_BY_ID_SUCCESS = 'FETCH_ITEM_BY_ID_SUCCESS';
export const FETCH_ITEM_BY_ID_FAILURE = 'FETCH_ITEM_BY_ID_FAILURE';
// Get Items for Table


export function fetchItems(args) {
    return dispatch => {
        dispatch(fetchItemsBegin());
        return keepspaceApi.getItems(args)
            .then(json => {
                dispatch(fetchItemsSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchItemsFailure(error))
            });
    };
}

export const updatePageNumber = (pageNo) => ({
    type: UPDATE_PAGE_NUMBER,
    payload: { pageNo }
});

export const updatePerPageCount = (count) => ({
    type: UPDATE_PER_PAGE_COUNT,
    payload: { count }
});



function handleErrors(response) {
    if (!response.ok) {
        apiErrorHandler(response)
        throw Error(response.statusText);
    }
    
    return response;
}


// export const archiveItemBegin = () => ({
//     type: ARCHIVE_ITEM_BEGIN
// });

// export const archiveItemSuccess = item => ({
//     type: ARCHIVE_ITEM_SUCCESS,
//     payload: { item }
// });

// export const archiveItemFailure = error => ({
//     type: ARCHIVE_ITEM_FAILURE,
//     payload: { error }
// });



export const fetchItemsBegin = () => ({
    type: FETCH_ITEMS_BEGIN
});

export const fetchItemsSuccess = items => ({
    type: FETCH_ITEMS_SUCCESS,
    payload: { 
        products:items.data.products,
        perPage:items.data.pagination.per_page,
        page:items.data.pagination.current_page,
        totalCount:items.data.pagination.total_count,
        totalPages:items.data.pagination.total_pages,
        categories:items.data.filters.categories,
        pagination: items.data.pagination
     }
});

export const fetchItemsFailure = error => ({
    type: FETCH_ITEMS_FAILURE,
    payload: { error }
});


// Filtering & Searching the Table


export function setSearchTerm(term) {
    return { type: SET_SEARCH_TERM, term };
};

export function setSearchActive(active) {
    return { type: SET_SEARCH_ACTIVE, active };
};

export const searchItemsBegin = () => ({})


// Add new items

export const createItemBegin = () => ({
    type: CREATE_ITEM_BEGIN
});

export const createItemSuccess = ({items}) => ({
    type: CREATE_ITEM_SUCCESS,
    payload: { items }
});

export const createItemFailure = error => ({
    type: CREATE_ITEM_FAILURE,
    payload: { error }
});

export const clearProductsAction = () => async (dispatch) => {
    await Promise.all([
        dispatch(setSearchTerm("")), // <-- async dispatch chaining in action
        dispatch(clearProducts())
      ]);
};

export const clearProducts = () => ({
    type: CLEAR_PRODUCTS
});

export function createItem(formData) {
    return dispatch => {
        dispatch(createItemBegin());
        return keepspaceApi.createItem(formData)
            .then(json => {
                dispatch(createItemSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createItemFailure(error))
            });
    }
}


export function searchOrdersBySKU(sku, startDate, endDate) {
    return dispatch => {
        return fetch(
            (apiPath + `/item_stocks/orders/?sku=${encodeURIComponent(sku)}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`),
            {
                method: 'GET',
                headers: authHeaders,
            }
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(searchOrdersBySkuSuccess(json));
            })
            .catch(error => {
                dispatch(searchOrdersBySkuFailure(error));
            });
    }
}

export const searchOrdersBySkuSuccess = ({ itemStocks, monthlyOutbounds, itemSku }) => {
    return {
        type: SEARCH_ORDER_BY_SKU_SUCCESS,
        payload: { itemStocks, monthlyOutbounds, itemSku },
    }
}

export const searchOrdersBySkuFailure = (error) => {
    return {
        type: SEARCH_ORDER_BY_SKU_FAILURE,
        payload: { error },
    }
}


export function updateItem(formData, id) {
    return dispatch => {
        dispatch(updateItemBegin());
        return keepspaceApi.updateItem(formData, id)
            .then(json => {
              dispatch(updateItemSuccess(json));
              return json;
            })
            .catch(error => {dispatch(updateItemFailure(error))});
    }
}


export function breakUnit(formData, id) {
    return dispatch => {
        dispatch(updateItemBegin());
        return keepspaceApi.breakUnit(formData, id)
            .then(json => {
              dispatch(updateItemSuccess(json));
              return json;
            })
            .catch(error => {dispatch(updateItemFailure(error))});
    }
}


export const updateItemBegin = () => ({
  type: UPDATE_ITEM_BEGIN,
})


export const updateItemSuccess = (item) => ({
  type: UPDATE_ITEM_SUCCESS,
  payload: { item }
})

export const updateItemFailure = error => ({
    type: UPDATE_ITEM_FAILURE,
    payload: { error }
});

export const updateArchivedFilter = (property, value) => ({
    type: UPDATE_ARCHIVED_FILTER,
    property,
    value
});

export const updateAvailableFilter = (property, value, isChecked) => ({
    type: UPDATE_AVAILABLE_FILTER,
    property,
    value,
    isChecked
});


export const updateLowStockFilter = (isChecked) => ({
    type: UPDATE_LOW_STOCK_FILTER,
    isChecked
});


export function createItemsFromCSV(formData) {
    return dispatch => {
        // dispatch(createItemBegin());
        return fetch(
            (apiPath + '/items_csv/'),
            {
                method: 'POST',
                headers: authHeaders,
                body: formData,
            }
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                return json;
            })
            .catch(error => {
              console.log(error)
            });
    }
}

export function fetchItemById(id) {
    return dispatch => {
        dispatch(fetchItemByIDBegin());
        return keepspaceApi.getItemById(id)
            .then(json => {
                dispatch(fetchItemByIDSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchItemByIDFailure(error));
                return error
            });
    }
}


export const fetchItemByIDBegin = () => ({
    type: FETCH_ITEM_BY_ID_BEGIN
});

export const fetchItemByIDSuccess = item => ({
    type: FETCH_ITEM_BY_ID_SUCCESS,
    payload: { item }
});

export const fetchItemByIDFailure = error => ({
    type: FETCH_ITEM_BY_ID_FAILURE,
    payload: { error }
});

export const setMultipleSkuError = error => ({
    type: SET_MULTIPLE_SKU_ERROR,
    payload: { error }
});

export const updateCategoryFilter = (value,isChecked)=> ({
    type: UPDATE_CATEGORY_SELECTION,
    value,
    isChecked
});

export function printBarcode(itemId) {
    return dispatch => {
        return keepspaceApi.printSkuBarcode(itemId)
            .then(json => {
                return {success: true, loading: false, error: null, data: json};
            })
            .catch(error => {
                return {success: false, loading: false, error: {error_message: error.response.statusText}, data: null};
            });
    };
}