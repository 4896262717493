import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';

import Dialog from '@material-ui/core/Dialog';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import NewTerminology from './NewTerminology';
import {UID} from 'AppConstants';
// import SKUNewForm from 'components/Forms/SKUForm/SKUNewForm';

const ResponsiveDialog = props => {

    const [open, setOpen] = useState(true)

    const classes = useStyles();

    function handleClose(){
        setOpen(false);
        setTimeout(() => {
            props.history.push(`/user/${UID}/terminologies`);
        }, 100);
    }

    return (
        <Dialog
            open={open}
            onEscapeKeyDown={handleClose}
            onClose={handleClose}
            onBackdropClick={handleClose}
            aria-labelledby="NewTerminology"
            overflow="visible"
        >
            <div className={classes.dialog}>
              <NewTerminology handleClose={handleClose} />
            </div>
        </Dialog>
    );
}

const useStyles = makeStyles(theme => ({
    root: {
    },
  }));

export default withMobileDialog({
    breakpoint: 'sm',
})(ResponsiveDialog);


