import React from 'react';
import PropTypes from 'prop-types';

import { applyDateInterface } from 'Interfaces';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

const InfoCard = props => {
  const { title, bundle, attributes, classes, humanize } = props;

  return (

    <Card>
          <CardHeader title={title} />
          <Divider />
          <CardContent className={classes.content}>
            <Table>
              <TableBody>
                {attributes.map((attribute, i) =>
                  <TableRow key={attribute}>
                    <TableCell className={classes.tableCell}>{ attribute === 'quantity_available' ? 'Estimated Quantity Available' : humanize(attribute)}</TableCell>
                    <TableCell className={classes.tableCell}>{ attribute === 'created_at' ? applyDateInterface(bundle[attribute]) : bundle[attribute] }</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </CardContent>
    </Card>
  );
};

InfoCard.propTypes = {
  className: PropTypes.string,
  bundle: PropTypes.object.isRequired
};

export default InfoCard;
