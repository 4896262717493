import React, {Component} from 'react';
import {withStyles, FormControl, MenuItem, Checkbox, ListItemText, Button, Switch, Typography} from '@material-ui/core';

import SpaceFilterCollapse from './SpaceFilterCollapse'

import {connect} from 'react-redux';

import Sort from '@material-ui/icons/Sort';


class OrderFilterDrawerContents extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    const {classes, archived, onHandFilter, available, lowStock, handleClearFilters, lowStockClick, availableClick, archivedClick, statusClick} = this.props;
    return (
      <>
        <SpaceFilterCollapse title='Category' icon={Sort} collapseItems={['Test']}>
          <FormControl className={classes.selectForm}>
            {this.props.categories.map(sortKey => (
              <MenuItem key={sortKey.id} id={sortKey.id} value={sortKey.id} onClick={() => statusClick(sortKey.id, !sortKey.isChecked)} >
                <Checkbox checked={sortKey.isChecked} value={sortKey.id} />
                <ListItemText primary={sortKey.title} />
              </MenuItem>
            ))}
          </FormControl>
        </SpaceFilterCollapse>

        <SpaceFilterCollapse title='Archived' icon={Sort} collapseItems={['Test']}>
          <FormControl className={classes.selectForm}>
            <MenuItem id='archived' onClick={() => archivedClick('yes', !archived.yes)} >
              <Checkbox checked={archived.yes} value={archived.yes} />
              <ListItemText primary='Yes' />
            </MenuItem>
            <MenuItem id='archived' onClick={() => archivedClick('no', !archived.no)} >
              <Checkbox checked={archived.no} value={archived.no} />
              <ListItemText primary='No' />
            </MenuItem>
            <MenuItem id='archivedAny' onClick={() => archivedClick('any', !archived.any)} >
              <Checkbox checked={archived.any} value={archived.any} />
              <ListItemText primary='Any' />
            </MenuItem>
          </FormControl>

        </SpaceFilterCollapse>

        <SpaceFilterCollapse title='Availibility' icon={Sort} collapseItems={['Test']}>
          <div className={classes.availibility}>
            <FormControl className={classes.selectForm}>
              <div className={classes.availibilityHeader}>In storage</div>
              {Object.keys(onHandFilter).map(sortKey => (
                <MenuItem key={sortKey} id={sortKey} value={sortKey} onClick={() => availableClick('onHandFilter', sortKey, !onHandFilter[sortKey])} >
                  <Checkbox checked={onHandFilter[sortKey]} value={onHandFilter[sortKey]} />
                  <ListItemText primary={sortKey} className={classes.capitaliseText} />
                </MenuItem>
              ))}
            </FormControl>
            <FormControl className={classes.selectForm}>
              <div className={classes.availibilityHeader}>Available</div>
              {Object.keys(available).map(sortKey => (
                <MenuItem key={sortKey} id={sortKey} value={sortKey} onClick={() => availableClick('availableFilter', sortKey, !available[sortKey])} >
                  <Checkbox checked={available[sortKey]} value={available[sortKey]} />
                  <ListItemText primary={sortKey} className={classes.capitaliseText} />
                </MenuItem>
              ))}
            </FormControl>
          </div>

        </SpaceFilterCollapse>

        <div className={classes.drawerRow}>
          <Typography>Low Stock</Typography>
          <Switch
            type='checkbox'
            checked={lowStock}
            value={lowStock}
            onClick={() => lowStockClick(!lowStock)}
            color="primary"
          />
        </div>

        <div className={classes.footer}>
          <Button onClick={handleClearFilters}>
            Clear filters
          </Button>
        </div>
      </>
    )
  }
}

const styles = theme => ({
  root: {

  },
  selectForm: {
    width: '100%'
  },
  datePicker: {
    display: 'flex',
    flexDirection: 'row'
  },
  calendar: {
    margin: 10
  },
  footer: {
    float: 'right',
    padding: '20px'
  },
  availibility: {
    display: 'flex',
    flexDirection: 'row',
    paddingTop: '8px'
  },
  availibilityHeader: {
    paddingLeft: '16px'
  },
  drawerRow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
  },
  capitaliseText: {
    "text-transform": "capitalize"
  }
})

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  categories: state.spaceReducer.categoriesFilter,
  archived: state.spaceReducer.archivedFilter,
  // availibility: state.spaceReducer.availibility,
  onHandFilter: state.spaceReducer.onHandFilter,
  available: state.spaceReducer.availableFilter,
  availableValues: Object.values(state.spaceReducer.availableFilter),
  // onHandFilterValues: Object.values(state.spaceReducer.onHandFilter),
  lowStock: state.spaceReducer.lowStockFilter,
  // categories: applyCategoriesInterface(state.categoriesReducer.categories),
})

export default connect(mapStateToProps)(withStyles(styles)(OrderFilterDrawerContents));
