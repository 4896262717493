import {
    FETCH_CUSTOMERS_BEGIN,
    FETCH_CUSTOMERS_SUCCESS,
    FETCH_CUSTOMERS_FAILURE,
    FETCH_CUSTOMER_BY_ID_BEGIN,
    FETCH_CUSTOMER_BY_ID_SUCCESS,
    FETCH_CUSTOMER_BY_ID_FAILURE,
    CREATE_CUSTOMER_BEGIN,
    CREATE_CUSTOMER_SUCCESS,
    CREATE_CUSTOMER_FAILURE,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_FAILURE,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_FAILURE
} from 'actions/customerActions';

import { applyCustomerInterface } from 'Interfaces';

const initialState = {
    customers: [],
    loading: false,
    error: null,
    uploading: false,
}

export default function customersReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_CUSTOMERS_BEGIN:
            return  {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_CUSTOMERS_SUCCESS:
            return  {
                ...state,
                loading: false,
                customers: action.payload.customers.map(customer => ({
                    ...applyCustomerInterface(customer)
                }))
            };
        case FETCH_CUSTOMERS_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
                customers: []
            };
        case FETCH_CUSTOMER_BY_ID_BEGIN:
            return {
                ...state,
                childLoading: true,
                error: null
            };

        case FETCH_CUSTOMER_BY_ID_SUCCESS:
            return {
                ...state,
                childLoading: false,
                customer: action.payload.customer
            };

        case FETCH_CUSTOMER_BY_ID_FAILURE:
            return {
                ...state,
                childLoading: false,
                error: action.payload.error,
            };
        case CREATE_CUSTOMER_BEGIN:
            return {
                ...state,
                uploading: true,
                error: null
            };
        case CREATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                uploading: false,
                customers: [action.payload.customer, ...state.customers ]
            };
        case CREATE_CUSTOMER_FAILURE:
            return {
                ...state,
                uploading: false,
                error: action.payload.error,
            };
        case DELETE_CUSTOMER_SUCCESS:
            return {
              ...state,
              uploading: false,
              error: null,
              customers: state.customers.filter(customer => customer.id !== action.payload.id)
            };
       case DELETE_CUSTOMER_FAILURE:
            return {
                ...state,
                uploading: false,
                error: action.payload.error
            };
        case UPDATE_CUSTOMER_SUCCESS:
            let updatedCustomers= state.customers.map(customer => customer.id === action.payload.customer.id ?
               action.payload.customer : customer
            )
            return {
              ...state,
              customer: action.payload.customer,
              customers: updatedCustomers
            }
        case UPDATE_CUSTOMER_FAILURE:
            return {
                ...state,
                uploading: false,
                error: action.payload.error
            };

        default:
            return state
    }
}
