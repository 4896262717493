import React, { PureComponent } from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { Route } from "react-router";
import Header from "components/Header/Header";
import AddButton from "components/Button/AddButton";
import AddNew from "components/Help/AddNew";
import Terminology from "./components/Terminology";
import TerminologyAdd from "./components/TerminologyAdd";
import { fetchTerminologies } from "actions/terminologyActions";
import {UID} from 'AppConstants';
import checkPermission from "components/checkPermission";

class Terminologies extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (!this.props.loading) {
      if (this.props.terminologies.length === 0) {
        this.props.dispatch(fetchTerminologies());
      }
    }
  }

  permittedToAccess = (page) => {
    return checkPermission(this.props.currentOrganisation.currentOrganisationUser.role, page)
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Header title="Orders" subtitle="Terminologies" />
        <AddButton
          text="Add Terminology"
          link={`/user/${UID}/terminologies/add`}
          tooltip="Create Terminology"
          disabled={!this.permittedToAccess("terminologies:create")}
        />

        {(this.props.terminologies.length === 0 && this.permittedToAccess("terminologies:create")) ? (
          <AddNew title="terminology" />
        ) : (
          <>
            {this.props.terminologies.map((terminology) => (
              <Terminology
                key={terminology.id}
                terminology={terminology}
                error={this.props.error}
                permittedToAccess={this.permittedToAccess}
              />
            ))}
          </>
        )}
        <Route
          path={`/user/${UID}/terminologies/add`}
          exact
          render={(routeProps) => (
            <TerminologyAdd {...routeProps} {...this.props} permittedToAccess={this.permittedToAccess}/>
          )}
        />
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
});

const mapPropsToState = (state, ownProps) => ({
  ...ownProps,
  terminologies: state.terminologiesReducer.terminologies,
  loading: state.terminologiesReducer.loading,
  error: state.terminologiesReducer.error,
  currentOrganisation: state.organisationsReducer.currentOrganisation
});

export default connect(mapPropsToState)(withStyles(styles)(Terminologies));
