import React from 'react'
import { Grid } from '@material-ui/core';
import SummaryCard from '../SummaryCard'


function P4Summary(props) {
    const { parentState } = props;

    return (
        <>
          <Grid container spacing={1}>
              <SummaryCard item={parentState} type="basic" categories={props.categories} error={props.error}/>
              {parentState.units.map((unit, index)=>
                <SummaryCard item={unit} key={index} index={index} units={parentState.units} error={props.error}/>
              )}
          </Grid>
        </>
    )
}

export default P4Summary;
