//import {UID} from 'AppConstants';  


export const Content = [

    {
        title: "How to create an SKU for your product?",
        video: "https://www.youtube.com/embed/yoB68btPP-M",
        children: [
            {
                tag: "p",
                text: "KeepSpace refers to products as SKUs. An SKU means for a Store Keeping Unit, which helps us to identify what your product is identified as in your account and in our system."
            },

            {
                tag: "li",
                text: "To create an SKU, go to the sidebar menu and click on Space -> <a href='/user/:uid/space/products'>Products</a>"
            },
            {
                tag: "li",
                text: "You can add your products one by one by clicking on the ‘ADD PRODUCT’ button. However, if you have many products to add, you can also upload a CSV file. To do that, please let one of our team members know."
            },
            {
                tag: "li",
                text: "After clicking the ‘ADD PRODUCT’ button, you can input the details of the product.",
                children: [
                    {
                        tag: "li",
                        text: 'The Title is the name of the product.'
                    },
                    {
                        tag: "li",
                        text: 'A Barcode is just a code for us to scan and put on the product. if you don’t have one, it’s okay for now.'
                    },
                    {
                        tag: "li",
                        text: 'The HS Code is needed for customs and is usually a 7 to 8 digit code with periods or dashes.'
                    },
                    {
                        tag: "li",
                        text: 'The image of the product helps us to know how it looks like when picking. Please upload a JPEG or PNG file.'
                    },
                    {
                        tag: "li",
                        text: 'Once you’ve filled it all out, click NEXT'
                    }
                ]
            },
            {
                tag: "li",
                text: "We need to know the exact size and weight of the SKU (in metres and kilograms). This relates to how much is charged for storage."
            },
            {
                tag: "li",
                text: "After you’ve measured and input the metrics, click NEXT."
            },
            {
                tag: "li",
                text: "The next page asks for values of costs. It is mainly going to be used for your insurance claims."
            },
            {
                tag: "li",
                text: "Low stock refers to the minimum amount of stock to notify you that a restock will be needed soon."
            },
            {
                tag: "li",
                text: "When that’s done, a summary of the input information will appear."
            },
            {
                tag: "li",
                text: "PLEASE DOUBLE CHECK as administration fees will incur if it needs to be fixed later. If it seems right, click on ‘CREATE’ and then click on ‘AGREE’."
            },
            {
                tag: "p",
                text: "You’ve successfully created an SKU!"
            },
        ]
    },

    {
        title: "How to create a Bundle?",
        video: "https://www.youtube.com/embed/O12W9mDMoJA",
        children: [
            {
                tag: "p",
                text: "A bundle is a collection of two or more SKUs which is identified as one product on the system. A SKU for a bundle is not provided from the manufacturer. So you can create one to use on the ecommerce platforms and their system."
            },

            {
                tag: "li",
                text: "To create a bundle, first, go to the sidebar menu and click on Space -> <a href='/user/:uid/space/bundles'>Bundles</a>."
            },
            {
                tag: "li",
                text: "On the page, click on ‘ADD BUNDLE’"
            },
            {
                tag: "li",
                text: "After inputting the information, click on ‘ADD SKU TO BUNDLE’",
            },
            {
                tag: "li",
                text: "After the page changes, click on the SKU that you want to add to the bundle."
            },
            {
                tag: "li",
                text: "If you’re done, click on ‘CREATE BUNDLE’."
            },
            
            {
                tag: "p",
                text: "Now you have successfully created a bundle!"
            },
            {
                tag: "p",
                text: "You can look at the details of the bundle by clicking on it from the list.It also calculates the maximum amount of sets (bundles) that is in stock, depending on the stock of the SKUs that make up the bundle. ORDERLOG is a record of how many times the particular bundle was requested and who requested it, to help you know the movement of your stocks."
            },
        ]
    },

    {
        title: "How to create an Inbound order on KeepSpace’s system?",
        video: "https://www.youtube.com/embed/ir8HNrBkq94",
        children: [
            {
                tag: "p",
                text: "An inbound order is what will come into KeepSpace’s warehouse."
            },

            {
                tag: "li",
                text: "To create an inbound order, first, go Orders -> <a href='/user/:uid/orders/summary/'>Summary</a>."
            },
            {
                tag: "li",
                text: "On the summary page, click on ‘ADD ORDER’."
            },
            {
                tag: "li",
                text: "Next, click on ‘New Inbound Order’"
            },
            {
                tag: "li",
                text: "After the page changes, click on ‘ADD SKU TO ORDER’, and select the SKU that will come to the warehouse."
            },
            {
                tag: "li",
                text: "Next, add the quantity of each SKU that you organised to bring into KeepSpace."
            },
            {
                tag: "li",
                text: "After that, in the ‘Where are they coming from?’ Section, please input the details of your suppliers. (It is the same information that is on the shipping lists.)"
            },
            {
                tag: "li",
                text: "Next, please choose the Courier company that you’ll use to send your products."
            },
            {
                tag: "li",
                text: "Then, please provide your waybill / consignment number. (Very important for the tracking number that the courier provides.) When we get your inbound, we can scan the waybill and identify which inbound order or SKU has come. So please provide the information."
            },
            {
                tag: "li",
                text: "The estimated arrival date can be vague."
            },
            {
                tag: "li",
                text: "Lastly, click on ‘CREATE ORDER’ and you’re finished!"
            },
            {
                tag: "p",
                text: "After you’ve created an inbound order, you can see an overview of the order.You can check the status of the order on the top right hand side."
            },
            {
                tag: "p",
                text: "",
                children: [
                    {
                        tag: "li",
                        text: 'When the order has been created: ‘Info Received’.'
                    },
                    {
                        tag: "li",
                        text: 'When the team have checked the order on the system, but it’s not at the warehouse: ‘Awaiting Arrival’.'
                    },
                    {
                        tag: "li",
                        text: 'When someone has identified that the order has arrived and is checking quantity and quality and stocking on shelf: ‘Stocking’.'
                    },
                    {
                        tag: "li",
                        text: 'When it is all done and stocked: ‘Processed’.'
                    }
                ]
            },
            {
                tag: "p",
                text: "You can see the stock number from Products, and also the cost of the inbound from Summary -> PRICING."
            },
        ]
    },

    {
        title: "How to create an Outbound order on KeepSpace system?",
        video: "https://www.youtube.com/embed/IiMYO081p_c",
        children: [
            {
                tag: "p",
                text: "Congratulations! Your product is going to get sent out to the person you’ve sold it to!"
            },

            {
                tag: "p",
                text: "There are 3 main ways you can create an outbound order.",
                children: [
                    {
                        tag: "li",
                        text: 'Using an API integration. We integrate our system with your ecommerce platform, so outbound orders are automatically generated.'
                    },
                    {
                        tag: "li",
                        text: 'Manually generate in the system. (Which will be covered in this video.)'
                    },
                    {
                        tag: "li",
                        text: 'Sending a CSV file.'
                    },
                ]
            },
            {
                tag: "p",
                text: "To manually create an outbound order in our system...",
                children: [
                    {
                        tag: "li",
                        text: "Go to Orders -> <a href='/user/:uid/orders/summary/'>Summary</a> -> ADD ORDER -> New Outbound Order."
                    },
                    {
                        tag: "li",
                        text: 'Click on the ‘ADD SKU TO ORDER’ to add the SKUs that are going to be sold. Then, change the quantity corresponding to the amount that is sold.'
                    },
                    {
                        tag: "li",
                        text: 'After that, you have to insert the customer details.'
                    },
                    {
                        tag: "li",
                        text: 'If you have a regular customer, you can store their details in the system and search for it to pre-fill the forum for outbounds.'
                    },
                    {
                        tag: "li",
                        text: "The details can be stored by going to Manage -> <a href='/user/:uid/manage/customers/'>Customers</a> -> ‘ADD CUSTOMER’."
                    },
                    {
                        tag: "li",
                        text: '(If you’re halfway done an order, a third option ‘Continue Previous Order’ is available under ‘ADD ORDER’.)'
                    },
                    {
                        tag: "li",
                        text: 'Reference Number - helps us know whatever order you want to produce this as for your system.'
                    },
                    {
                        tag: "li",
                        text: 'Choose the shipping method, add some delivery instructions if needed, and click on “CREATE ORDER’.'
                    },
                ]
            },
            {
                tag: "p",
                text: "Now you’re done!"
            },
            {
                tag: "p",
                text: "Just like for inbound orders, you can check the stage of your outbound orders on the top right hand side.",
                children: [
                    {
                        tag: "li",
                        text: 'When the outbound order is first created: ‘Info Received’.'
                    },
                    {
                        tag: "li",
                        text: 'Someone in the warehouse is picking the ordered products and packing them for shipment: ‘Pick & Pack’.'
                    },
                    {
                        tag: "li",
                        text: 'When a tracking ID is attached along with the shipping label: ‘Shipping’.'
                    },
                    {
                        tag: "li",
                        text: 'The products have been sent out (with corrections made when required): ‘Processed’.'
                    },
                ]
            },
            {
                tag: "p",
                text: "Warning: you cannot change details after info received status. If you found a mistake, please leave a comment in the CHAT section (in the tab next to OVERVIEW) so our team can see it and fix it for you. If it’s been over an hour and there is no reply, please call us."
            },
        ]
    },

    {
        title: "How to look at analytics in KeepSpace system?",
        video: "https://www.youtube.com/embed/0kR-G39T1k0",
        children: [
            {
                tag: "p",
                text: "There are two ways to access the analytics page.",
                children: [
                    {
                        tag: "li",
                        text: "On the sidebar menu, click on Analytics -> <a href='/user/:uid/analytics/sku_breakdown/'>SKU Breakdown</a> -> search for the SKU of interest."
                    },
                    {
                        tag: "li",
                        text: "Go to Space -> <a href='/user/:uid/space/products'>Products</a> -> Click on an SKU of interest -> click on the ‘reports’ icon on the top right hand corner."
                    },
                ]
            },
            {
                tag: "p",
                text: "In the SKU Breakdown page, you can click on an order within the list to go back to that order’s details."
            },
            {
                tag: "p",
                text: "From the page with the order’s details, you can click on a different SKU to access that particular SKU’s analytics."
            },
            {
                tag: "p",
                text: "We hope this cycle will help you to easily comprehend your SKUs, stocks and orders."
            },

            {
                tag: "p",
                text: "If you need more support, feel free to contact us at <a href='mailto:support@keepspace.com.au'>support@keepspace.com.au</a> or our other channels."
             }
        ]
    }
]