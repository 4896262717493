import React, {PureComponent} from 'react';
import {Route} from 'react-router-dom';


import SpaceTable from 'components/Table/SpaceTable';
import SpaceGrid from 'components/Grid/SpaceGrid';
import SpaceDetail from 'views/user/Space/SpaceDetail';
import SpaceAdd from './SpaceAdd';
import SpaceEdit from './SpaceEdit';
import checkPermission from "components/checkPermission";
// @Material-UI (OSS)
import {CardActions} from '@material-ui/core';
import {withStyles} from '@material-ui/styles';
import Loader from 'layouts/AdminLayout/components/Loader';
import Pagination from 'layouts/Pagination';

import {connect} from 'react-redux';
import {
  fetchItems,
  setSearchActive,
  setSearchTerm,
  updatePerPageCount,
  updatePageNumber,
  clearProducts,
  updateArchivedFilter,
  updateAvailableFilter,
  updateLowStockFilter,
  updateCategoryFilter,
  clearProductsAction
} from 'actions/spaceActions';

import SpaceMenuBar from './SpaceMenuBar';

import Header from 'components/Header/Header';
import {createItemsFromCSV} from 'actions/spaceActions';


const styles = theme => ({
  root: {
    padding: theme.spacing(3),
    width: '100%',
    margin: 'auto'
  },
  rootWrapper: {

  },
  progress: {
    borderRadius: "0px"
  },
  actions: {
    padding: theme.spacing(1),
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '& .MuiTablePagination-actions': {
      display: 'none'
    },
    '& .MuiTablePagination-input + .MuiTablePagination-caption': {
      display: 'none'
    }
  },
});

export class Space extends PureComponent {
  _isMounted = false

  constructor(props) {
    super(props);
    let grid;
    if(!sessionStorage.getItem('items-grid')) {
      grid = true;
    }
    else {
      grid = JSON.parse(sessionStorage.getItem('items-grid'));
    }
    this.state = {
      searching: true,
      searchValue: '', // Search value from SpaceSearch appears here,
      grid: grid,
      csv: null,
      countries: props.countries || [],
      fetchProducts: false,
      clearFilter: false
    };
    this.createItems = this.createItems.bind(this);
    this.swapGrid = this.swapGrid.bind(this)
    this.handleSearchInput = this.handleSearchInput.bind(this)
    this.fetchSearchResult = this.fetchSearchResult.bind(this)
    this.mapColor = this.mapColor.bind(this)
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this)
    this.handleChangePage = this.handleChangePage.bind(this)
    //  filters
    this.handleClearFilters = this.handleClearFilters.bind(this)
    this.lowStockClick = this.lowStockClick.bind(this)
    this.availableClick = this.availableClick.bind(this)
    this.archivedClick = this.archivedClick.bind(this)
    this.statusClick = this.statusClick.bind(this)
  }


  async componentDidMount() {
    this._isMounted = true;
    await this.props.dispatch(clearProductsAction());
    if(!this.props.loading) {
      this.fetchProducts();
    }
  }

  componentDidUpdate() {

    if(this.state.fetchProducts && !this.props.loading) {
      this.fetchProducts();
    }
  }

  swapGrid() {
    sessionStorage.setItem('items-grid', JSON.stringify(!this.state.grid))
    this.setState({
      grid: !this.state.grid
    });
  }

  handleChangeRowsPerPage(event) {

    this.props.dispatch(updatePageNumber(1));
    this.props.dispatch(updatePerPageCount((event.target.value === 'All') ? this.props.totalCount : event.target.value));
    this.setFetchProducts(true);
  }

  setFetchProducts(val) {
    this.setState({
      fetchProducts: val
    })
  }
  handleChangePage(event, pageNumber) {
    this.props.dispatch(updatePageNumber(pageNumber));
    this.setFetchProducts(true);
  }

  fetchProducts() {

    this.setFetchProducts(false);

    let param = "&per_page=" + this.props.perPage + "&page=" + this.props.page;

    if(this.props.searchTerm) {
      param = param + "&user_search_items=" + this.props.searchTerm;
    }
    if(this.props.categoriesFilter) {
      let filtered1 = this.props.categoriesFilter.filter(obj => obj.isChecked == true);
      let arr = [], categoryLink = "";
      let res = filtered1.map(function (o) {
        arr.push(o.id);
        return arr;
      });
      for(const element of arr) {
        categoryLink = categoryLink + `&category_ids[]=${element}`;
      }
      param = param + categoryLink;
    }
    if(this.props.lowStockFilter) {
      param = param + "&is_low_stock=" + this.props.lowStockFilter
    }

    let isArchivedVal = Object.keys(this.props.archivedFilter).filter(k => this.props.archivedFilter[k]);
    if(isArchivedVal[0] != "any") {
      isArchivedVal = (isArchivedVal[0] == "yes") ? 1 : 0
      param = param + "&is_archived=" + isArchivedVal;
    }
    let isAvailableVal = Object.keys(this.props.availableFilter).filter(k => this.props.availableFilter[k]);
    if(isAvailableVal[0] != "any") {
      isAvailableVal = (isAvailableVal[0] == "yes") ? 1 : 0
      param = param + "&is_available=" + isAvailableVal;
    }
    let isInStorageVal = Object.keys(this.props.onHandFilter).filter(k => this.props.onHandFilter[k]);
    if(isInStorageVal[0] != "any") {
      isInStorageVal = (isInStorageVal[0] == "yes") ? 1 : 0
      param = param + "&is_in_stock=" + isInStorageVal;
    }

    this.props.dispatch(fetchItems(param));
    if(this.state.clearFilter) {
      this.setState({
        clearFilter: false
      })
    }
  }
  handleSearchInput(text) {
    this.props.dispatch(setSearchTerm(text));
  }

  fetchSearchResult(term) {
    const searchTerm = term;//this.props.searchTerm;
    if(searchTerm.length >= 3 || searchTerm.length === 0) {
      if(this.props.searchActive !== Boolean(searchTerm !== '')) {
        this.props.dispatch(setSearchActive(Boolean(searchTerm !== '')))
        this.setFetchProducts(true);
      }
    }
  }

  createItems(event) {

    let uploadedCsv = event.target.files[0]
    this.setState({
      csv: uploadedCsv
    })

    let formData = new FormData();

    formData.append('file', uploadedCsv);
    this.props.dispatch(createItemsFromCSV(formData))
  }

  mapColor(category_title) {
    if(this.props.categories === null) return null
    let category = this.props.categories.find(category => category.title === category_title)
    return category ? category.colour : '#45B5F2'
  }
  // filter methods

  statusClick(value, isChecked) {
    this.props.dispatch(updateCategoryFilter(value, isChecked));
    if(!this.state.clearFilter) {
      this.setFetchProducts(true);
    }

  }

  archivedClick(property, value) {
    this.props.dispatch(updateArchivedFilter(property, value));
    if(!this.state.clearFilter) {
      this.setFetchProducts(true);
    }
  }

  availableClick(property, value, isChecked) {
    this.props.dispatch(updateAvailableFilter(property, value, isChecked));
    if(!this.state.clearFilter) {
      this.setFetchProducts(true);
    }
  }

  lowStockClick(isChecked) {
    this.props.dispatch(updateLowStockFilter(isChecked));
    if(!this.state.clearFilter) {
      this.setFetchProducts(true);
    }

  }

  handleClearFilters() {
    this.setState({
      clearFilter: true
    }, function () {
      this.props.categoriesFilter.map(sortKey => this.statusClick(sortKey.id, false))
      this.lowStockClick(false)
      this.archivedClick('no', true);
      this.availableClick('availableFilter', 'any', 'true')
      this.availableClick('onHandFilter', 'any', 'true')
      if(this._isMounted) {
        this.setFetchProducts(true);
      }
    })

  }

  // clear filters on component unmount
  componentWillUnmount() {
    this._isMounted = false;
    this.handleClearFilters();
    this.props.dispatch(setSearchTerm(''));
    this.props.dispatch(clearProducts());
  }

  permittedToAccess = (page) => {
    return checkPermission(this.props.currentOrganisation.currentOrganisationUser.role, page)
  }

  render() {
    const {classes, items, onHandFilter, availableFilter, lowStockFilter, searchTerm, categories} = this.props;

    return (
      <div className={classes.rootWrapper}>


        <div className={classes.root}>

          {this.props.loading ? <Loader /> : null}
          <Header title="space" subtitle="Products" />
          <SpaceMenuBar
            items={items}
            searchTerm={searchTerm}
            handleSearchInput={this.handleSearchInput}
            fetchSearchResult={this.fetchSearchResult}
            swapGrid={this.swapGrid}
            grid={this.state.grid}
            createItems={this.createItems}
            handleClearFilters={this.handleClearFilters}
            lowStockClick={this.lowStockClick}
            availableClick={this.availableClick}
            archivedClick={this.archivedClick}
            statusClick={this.statusClick}
            permittedToAccess={this.permittedToAccess}
          />



          <>

            {this.state.grid ? <SpaceGrid itemsSearch={items} mapColor={this.mapColor} {...this.state} /> :
              <SpaceTable itemsSearch={items} mapColor={this.mapColor} {...this.state} />
            }

            {this.props.totalPages ? <CardActions className={classes.actions}>

              <Pagination pagination={this.props.pagination} onChangePage={this.handleChangePage} onChangeRowsPerPage={this.handleChangeRowsPerPage} />
            </CardActions> : null}

            <Route path='/user/:uid/space/products/add/' exact render={(routeProps) =>
              <SpaceAdd
                {...routeProps}
                {...this.props}
                countries={this.state.countries}
                permittedToAccess={this.permittedToAccess}
              />}
            />
            <Route path='/user/:uid/space/products/:id(\d+)/' exact render={(routeProps) =>
              <SpaceDetail
                {...routeProps}
                currentOrganisation={this.props.currentOrganisation}
              />}
            />
            <Route path='/user/:uid/space/products/:id(\d+)/edit' exact render={(routeProps) =>
              <SpaceEdit
                {...routeProps}
                countries={this.state.countries}
                permittedToAccess={this.permittedToAccess}
              />}
            />
          </>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  loading: state.spaceReducer.loading,
  error: state.spaceReducer.error,
  itemsLength: state.spaceReducer.items.length,
  items: state.spaceReducer.items,
  categories: state.spaceReducer.categories,
  currentOrganisation: state.organisationsReducer.currentOrganisation,
  perPage: state.spaceReducer.perPage,
  totalCount: state.spaceReducer.totalCount,
  page: state.spaceReducer.page,
  totalPages: state.spaceReducer.totalPages,
  lowStockFilter: state.spaceReducer.lowStockFilter,
  onHandFilter: state.spaceReducer.onHandFilter,
  availableFilter: state.spaceReducer.availableFilter,
  searchTerm: state.spaceReducer.searchTerm,
  categoriesFilter: state.spaceReducer.categoriesFilter,
  archivedFilter: state.spaceReducer.archivedFilter,
  countries: state.adminAuthReducer.countries,
  pagination: state.spaceReducer.pagination,
});

export default connect(mapStateToProps)(withStyles(styles)(Space));
