import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';

import CustomerCard from './CustomerCard';
import CustomerEdit from 'components/FormsPleaseStopDoingThis/CustomerForm/CustomerEdit';
import { useDispatch } from 'react-redux';
import { updateCustomer } from 'actions/customerActions';

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "750px",
    flexWrap: 'wrap',
    [theme.breakpoints.down("sm")]: {
      maxHeight: 'none'
    }
  }
}));

const Overview = props => {
  const { className, customer, countries, error, permittedToAccess, ...rest } = props;
  const classes = useStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setValues({ ...customer })
  }, [customer]);

  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleSave = (event) => {
    event.preventDefault();

    dispatch(updateCustomer(values))
      .then(result => {
        if (result) {
          // result is null when in error
          handleEditClose()
        }
      })
  }


  const handleChange = (event) => {
    // event.persist()
    const newValues = { ...values, [event.target.name]: event.target.value }
    setValues(newValues);
  };

  const fields =
    [
      { value: 'name', label: 'Name *', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'company_name', label: 'Company Name', required: [], options: [] },
      { value: 'email', label: 'Email address *', required: ['required', 'isEmail'], errorMessages: ['this field is required', 'email is not valid'], options: [] },
      { value: 'phone', label: 'Phone', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'address_line_1', label: 'Address 1 *', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'address_line_2', label: 'Address 2', required: [], options: [] },
      { value: 'postcode', label: 'Postcode *', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'suburb', label: 'Suburb *', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'state', label: 'State/Region *', required: ['required'], errorMessages: ['this field is required'], options: [] },
      { value: 'country', label: 'Country *', required: ['required'], errorMessages: ['required'], options: countries, select: true },
      { value: 'customer_type', label: 'Type', required: [], options: ["", "Wholesale", "Retail"], select: true },

      // {value: 'website', label: 'Website'}
    ]

  return (

    <Grid
      {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={2}
      direction="column"
    >
      <Grid
        item
        lg={6}
        md={6}
        xl={6}
        xs={12}
      >
        <CustomerCard
          title="Customer Info"
          customer={customer}
          rows={["name", "company_name", "phone", "email", "customer_type"]}
        />
      </Grid>

      <Grid
        item
        md={6}
        lg={6}
        xl={6}
        xs={12}
      >
        <CustomerCard
          title="Address Info"
          customer={customer}
          rows={["address_line_1", "address_line_2", "suburb", "state", "postcode", "country"]}
          edit={true}
          handleEditOpen={handleEditOpen}
          permittedToAccess={permittedToAccess}
        />
        {permittedToAccess("customers:edit") ?
          <CustomerEdit
            onClose={handleEditClose}
            open={openEdit}
            countries={countries}
            handleChange={handleChange}
            handleSave={handleSave}
            values={values}
            fields={fields}
            title="Edit Customer"
            error={error}
          /> : null}

      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        xl={6}
        xs={12}
      >
        <CustomerCard
          title="Value summary"
          customer={customer.details}
          rows={["orders", "total_sold"]}
          prefixes={['', '$']}
        />

      </Grid>
    </Grid>
  );
};

Overview.propTypes = {
  className: PropTypes.string
};

export default Overview;
