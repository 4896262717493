import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';

import { useDispatch, connect } from 'react-redux';
import { updateItem, fetchItemById, searchOrdersBySKU, breakUnit, printBarcode, setMultipleSkuError } from 'actions/spaceActions';

import Img from 'react-image';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  Paper,
  Snackbar,
  useMediaQuery,
  IconButton,
  Tooltip,
  Typography,
  useTheme

} from '@material-ui/core';

import ArchiveIcon from '@material-ui/icons/ArchiveOutlined';
import UnarchiveIcon from '@material-ui/icons/UnarchiveOutlined';
import EditIcon from '@material-ui/icons/Edit';
import AssessmentIcon from '@material-ui/icons/Assessment';
import Close from "@material-ui/icons/Close";
import QrCodeIcon from 'components/Icons/QrCodeIcon';

import moment from 'moment';

import ConfirmAlert from 'components/Help/ConfirmAlert';
import InventoryTable from 'components/Table/InventoryTable';
import { UID } from 'AppConstants';
// import { reverseUserRoute } from 'routes';
import Can from "components/Can";


const ResponsiveDialog = props => {
  const id = parseInt(props.match.params.id);
  const { archiveError, item_details } = props;
  const [open, setOpen] = useState(true)
  const dispatch = useDispatch();
  const { classes, currentOrganisation } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Logic for the ConfirmAlert component assumes that unitToBreak will be falsey
  // if the alert is shown but we're not breaking down an item (so we're archiving
  // the item). See handleAlertOpen.
  const [unitToBreak, setUnitToBreak] = useState(null);
  const [warehouseId, setWarehouseId] = useState(null);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {

    const fetchDataById = async () => {

      const fetchdata = await dispatch(fetchItemById(id));

      if(fetchdata?.response?.status !== 404) return;
      
      setError('Product Not Found. Redirecting to Products Page.');
      redirectToProductPage();
      
    }

    fetchDataById();

  }, [dispatch, id]);

  const handleClose = () => {
    setOpen(false);
    redirectToProductPage()
  }

  const redirectToProductPage = () => {
    setTimeout(() => {
      props.history.push(`/user/${UID}/space/products`);
    }, 3000);
  }

  const handleAlertOpen = (itemUnit, warehouse_id) => {
    // Currently, itemUnit could be:
    //    - an event object (if handleAlertOpen is triggered by archiving an item), or
    //    - the details of an item (if handleAlertOpen is triggered by breaking down an item)
    // Event objects don't have id and sku properties, so setUnitToBreak will
    // only be called if itemUnit is not an event object (i.e. we're not archiving,
    // we're breaking down).
    const itemUnitContainsItemDetails = itemUnit.id && itemUnit.sku
    const breakingDownItem = itemUnitContainsItemDetails
    if (breakingDownItem){
      setUnitToBreak(itemUnit);
      setWarehouseId(warehouse_id);
    } 

    setOpenAlert(true);
  };

  const handleSnackBarClose = () => {
    setError(null);
  }

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  const handleArchiveItem = () => {
    let formData = new FormData();
    formData.append('archived', !item_details.archived)
    dispatch(updateItem(formData, id))
    handleAlertClose();
  };

  const handleBreakUnit = () => {
    let formData = new FormData();
    formData.append('item_to_break_id', unitToBreak.id);
    formData.append('warehouse_id', warehouseId);

    dispatch(breakUnit(formData, id))
    handleAlertClose();
  };

  const openReport = () => {
    dispatch(searchOrdersBySKU(item_details.sku, moment().subtract(365, 'days'), moment()));
    setTimeout(() => {
      props.history.push(`/user/${UID}/analytics/sku_breakdown`)
    }, 100);
  }

  const handlePrintBarcode = async () => {
    setLoading(true)
    const response = await dispatch(printBarcode(id));
    if (response.success) {
      let filename = getFilename(response.data)
      let barcode = response.data.data

      downloadFile(filename, barcode)
    } else {
      setError(response.error)
    }
    setLoading(false)
  }

  const getFilename = (data) => {
    let headerLine = data.headers['content-disposition'];
    let startFileNameIndex = headerLine.indexOf('"') + 1
    let endFileNameIndex = headerLine.lastIndexOf('"');
    let filename = headerLine.substring(startFileNameIndex, endFileNameIndex);
    return filename;
  }

  const downloadFile = (filename, barcode) => {
    const pdfBlob = new Blob([barcode], { type: "application/pdf" })
    var downloadUrl = URL.createObjectURL(pdfBlob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename); //any other extension
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  let array

  (Boolean(item_details) && item_details.other_packaging_types) ?
    array = [...item_details.other_packaging_types, item_details] : item_details.id ? array = [item_details] : array = []

  let style = item_details && item_details.archived ? { filter: 'grayscale(100%)' } : null
  let prefix = item_details && item_details.archived ? "[ARCHIVED] " : ""

  let message = (item) => {
    let parentUnit = array.find(u => u.id === item.parent_id) || {}
    return `Break 1 ${item.unit_type} into ${item.items_per_unit} ${parentUnit.unit_type || 'Base unit'}s`
  }

  const archiveMessage = () => {
    const archiveVerb = item_details.archived ? "unarchive" : "archive"
    return `Are you sure you want to ${archiveVerb} this product?`
  }

  return (
    <Dialog
      fullScreen={fullScreen}
      maxWidth='lg'
      fullWidth={true}
      open={open}
      aria-labelledby="responsive-dialog-title"
      overflow="visible"
      PaperProps={{
        classes: {
          root: classes.grey
        }
      }}
    >
      <SuccessSnackbar
        open={(error) ? true : false}
        error={(error) ? error.error_message || error.error || error : null}
        onClose={handleSnackBarClose}
      />
      {(item_details.id) ? (

        <div className={classes.dialog}>
          <ConfirmAlert
            open={openAlert}
            description={unitToBreak ? message(unitToBreak) : archiveMessage()}
            onSubmit={unitToBreak ? handleBreakUnit : handleArchiveItem}
            onClose={handleAlertClose}
          />


          <div className={classes.dialogContent}>
            <Button onClick={handleClose} className={classes.closeBtn}>
              <Close />
            </Button>
            <div className={classes.root}>


              <div className={classes.raisedImageBlock}>

                <Img
                  className={classes.img}
                  style={style}
                  src={(item_details.image) ? item_details.image.url : ''}
                  loader={<CircularProgress className={classes.progress} size={250} variant={'indeterminate'} />}
                />

              </div>
              <Paper className={classes.paper}>

                <Card className={classes.card}>
                  <div className={classes.cardHeader}>

                    <div className={classes.skuTitle}>
                      {prefix + "SKU - " + item_details.sku}
                    </div>
                    <Can
                      role={currentOrganisation.currentOrganisationUser.role}
                      perform="products:edit"
                      yes={() => (
                        <div>
                          <Tooltip title={item_details.archived ? "Unarchive" : "Archive"}>
                            <span>

                              <IconButton
                                disabled={item_details.archived ? false : !item_details.archivable}
                                onClick={handleAlertOpen}
                              >
                                {item_details.archived ? <UnarchiveIcon /> : <ArchiveIcon />}
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => props.history.push(`/user/${UID}/space/products/${item_details.id}/edit/`)}
                            >
                              <EditIcon />
                            </IconButton>

                          </Tooltip>

                          <Tooltip title="Reports">
                            <IconButton
                              onClick={openReport}
                            >
                              <AssessmentIcon />
                            </IconButton>
                          </Tooltip>

                          <Tooltip title="Print Barcode">
                            <IconButton
                              onClick={handlePrintBarcode}
                            >
                              {loading ? <CircularProgress size={20} variant={'indeterminate'} /> : <QrCodeIcon />}
                            </IconButton>
                          </Tooltip>

                          <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={Boolean(archiveError)}
                            ContentProps={{
                              'aria-describedby': 'message-id',
                            }}
                            message={<span id="message-id">{archiveError ? archiveError.message : null}</span>}
                          />
                        </div>
                      )}
                    />

                  </div>
                  <Typography variant='h3' gutterBottom >{item_details.title}</Typography>
                  <Typography variant='body1' >{item_details.description}</Typography>


                  <div className={classes.flex}>

                    <div className={classes.leftBlock}>

                      <table className={classes.sizesTable}>
                        <tbody>
                          <tr style={{ fontSize: '1.3em' }}><td>Type</td><td> {item_details.unit_type || 'Base'}</td></tr>
                          {item_details.items_per_unit ? <tr><td>Items per unit</td><td> {item_details.items_per_unit}</td></tr> : null}
                          <tr><td>Length </td><td>{item_details.length}m </td></tr>
                          <tr><td>Width </td><td>{item_details.width}m  </td></tr>
                          <tr><td>Height </td><td>{item_details.height}m </td></tr>
                          <tr><td>Weight </td><td>{item_details.weight}kg</td></tr>


                        </tbody>
                      </table>
                    </div>

                    <div className={classes.leftBlock}>

                      <table className={classes.sizesTable} >
                        <tbody>
                          <tr style={{ fontSize: '1.3em' }}><td>Category </td><td> {item_details.category || 'Not supplied'}</td></tr>
                          <tr><td>Barcode </td><td> {item_details.barcode || 'Not supplied'}</td></tr>
                          <Can
                            role={currentOrganisation.currentOrganisationUser.role}
                            perform="products:viewFinancial"
                            yes={() => (
                              <>
                                <tr><td>Supplier Cost</td><td>{item_details.supplier_cost ? `$AUD ${item_details.supplier_cost}` : 'Not Supplied'}</td></tr>
                                <tr><td>Retail</td><td>{item_details.retail_cost ? `$AUD ${item_details.retail_cost}` : 'Not Supplied'}</td></tr>
                              </>
                            )}
                          />
                          <tr><td>HS Code </td><td>{item_details.hs_code}</td></tr>
                          <tr><td>Country Of Manufacture </td><td>{item_details.country_of_manufacture}</td></tr>

                        </tbody>
                      </table>
                    </div>

                  </div>

                  {array.length > 0 ? <InventoryTable item_details={item_details} classes={classes} handleAlertOpen={handleAlertOpen} array={array} message={message} /> : null}

                  <table className={classes.totalTable}>
                    <thead>
                      <tr>
                        <Tooltip title='Total of processed In and Out'>
                          <th>Orders</th>
                        </Tooltip>
                        <Tooltip title='Total outbounded to date'>
                          <th>Sold</th>
                        </Tooltip>
                        <Tooltip title='Quantity that could not be fulfilled'>
                          <th>Back Order</th>
                        </Tooltip>
                        <Tooltip title='Low Stock Alert is to provide a notification that when the inventory levels fall below a certain number that you are made aware'>
                          <th>Low Alert</th>
                        </Tooltip>
                        <Can
                          role={currentOrganisation.currentOrganisationUser.role}
                          perform="products:viewFinancial"
                          yes={() => (
                            <Tooltip title='Retail price multiplied by quantity sold'>
                              <th>Approx. Sales (before discounts)</th>
                            </Tooltip>
                          )}
                        />
                      </tr>
                    </thead>
                    <tbody className={classes.footer}>
                      <tr>
                        <td className={classes.totalTableTd}>{item_details.orders_total_count || '0'}</td>
                        <td className={classes.totalTableTd}>{item_details.sold_total || '0'}</td>
                        <td className={classes.totalTableTd}>{item_details.backorders || '0'} </td>
                        <td className={classes.totalTableTd}>{item_details.low_stock_alert || "N/A"}</td>
                        <Can
                          role={currentOrganisation.currentOrganisationUser.role}
                          perform="products:viewFinancial"
                          yes={() => (
                            <td className={classes.totalTableTd}>${(item_details.sold_total) * (item_details.retail_cost)}</td>
                          )}
                        />
                      </tr>
                    </tbody>
                  </table>

                </Card>
              </Paper>
            </div>
          </div>
          {/* <DialogActions>
                        <Button onClick={openReport} color="primary">REPORTS</Button>
                    </DialogActions> */}
        </div>
      ) : null}
    </Dialog>
  );
}

ResponsiveDialog.propTypes = {
  fullScreen: PropTypes.bool.isRequired,
};

const styles = theme => ({
  greyscale: {
    filter: 'greyscale(100%)'
  },
  grey: {
    background: '#f4f6f8'
  },
  root: {
    minHeight: '100px',
    flexWrap: 'wrap',
    fontSize: "14px"
  },
  dialog: {
    width: '90%',
    margin: 'auto',
    marginBottom: '50px',
    paddingTop: '10px',
    // paddingBottom: '25px',
    // [theme.breakpoints.down("xs")]: {
    //   width: '100%',
    // }
  },
  paper: {
    minHeight: 400,
  },
  progress: {
    borderRadius: '50%',
    height: 250,
    width: 250,
    zIndex: 1041
  },
  raisedImageBlock: {
    marginBottom: '-150px',
    marginLeft: 100,
    borderRadius: '50%',
    //display: 'inline-block',
    position: 'relative',
    height: 250,
    width: 250,
    zIndex: 1041,

    background: "white",
    boxShadow: "5px 5px 10px 0px rgba(0,0,0,0.20)",
  },
  img: {
    borderRadius: '50%',
    height: 250,
    width: 250,
    zIndex: 1041,
    objectFit: 'contain'
  },
  card: {
    padding: '32px 11%',
    position: 'relative',
    [theme.breakpoints.down("md")]: {
      padding: '30px',
    }
  },
  skuTitle: {
    fontSize: "1.75rem",
    // marginLeft: "250px",
    marginTop: "20px",
  },
  itemTitle: {
    fontSize: "1.6em",
  },
  flex: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    paddingTop: theme.spacing(3),
  },
  leftBlock: {
    flexGrow: 1,
    display: 'inline-block',
    marginBottom: '16px',
    minWidth: 180
  },
  totalTable: {
    marginTop: '30px',
    textAlign: 'center',
    width: '100%',
    border: '0px solid #000',
    marginBottom: '1px',
    marginTop: '12px',
    borderCollapse: 'collapse',
    tableLayout: 'auto',
    "& th": {
      padding: '8px',
    },
    "& td": {
      padding: '8px',
    }
  },
  cardHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginLeft: '250px',
    paddingBottom: '90px',
    [theme.breakpoints.down("xs")]: {
      flexDirection: 'column-reverse',
      marginLeft: "auto",
      marginTop: "150px",
      paddingBottom: '15px',
    }
  },
  sizesTable: {
    width: '100%',
    "& td": {
      paddingBottom: '15px',
      width: '50%'
    },
    [theme.breakpoints.down("xs")]: {
      margin: 'auto'
    }
  },
  footer: {
    borderTop: '1px grey solid'
  },
  totalTableTd: {
    wordWrap: 'anywhere',
    "& a": {
      color: theme.palette.keepspaceBlue.main,
      textDecoration: 'none',
      wordWrap: 'break-word'
    },
  },
  closeBtn: {
    float: "right"
  }
});


const mapStateToProps = state => ({
  archiveError: state.spaceReducer.error,
  items: state.spaceReducer.items,
  item_details: state.spaceReducer.fetchedItem

});

export default connect(mapStateToProps)(withStyles(styles)(ResponsiveDialog));

