import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Typography
} from '@material-ui/core';


const ProductsCard = props => {
  const {title, components, classes, attributes} = props;

  return (

    <Card>
      <CardHeader title={title} />
      <Divider />
      <CardContent className={classes.content}>
        <Table>
          <TableHead>
            <TableRow >
              {attributes.map(attribute => (
                <TableCell className={[classes.tableCell, classes.capitalize]}> {attribute}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {components.map((component, i) =>
              <TableRow key={component.item_sku}>
                <TableCell className={classes.tableCell}>
                  <Typography variant="h6">
                    {component.item_sku}
                  </Typography>
                  <Typography variant="body2">
                    {component.item_title}
                  </Typography>
                </TableCell>
                <TableCell className={classes.tableCell}>{component.item_quantity}</TableCell>
                <TableCell className={classes.tableCell}>{component.quantity_available}</TableCell>

              </TableRow>
            )}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

ProductsCard.propTypes = {
  className: PropTypes.string,
  bundle: PropTypes.object.isRequired
};

export default ProductsCard;
