import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
// import {InfoCard, ProductsCard}  from './components'
import InfoCard  from './components/InfoCard.jsx'
import ProductsCard  from './components/ProductsCard.jsx'
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    flexWrap: 'wrap',
    width: '100%',
    maxHeight: "300px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 'none'
    }
  },
  content: {
    padding: 0
  },
  tableCell: {
    padding: '14px 40px 14px 40px',
  },
  capitalize: {
    textTransform: 'capitalize'
  }
}));

const Details = props => {
  const classes = useStyles();
  const { className, bundle, components } = props

  function humanize(str) {
    return str.trim().split(/\s+/).map(function(str) {
      return str.replace(/_/g, ' ').replace(/\s+/, ' ').trim();
    }).join(' ').toLowerCase().replace(/^./, function(m) {
      return m.toUpperCase();
    });
  }

  return (
    <Grid
      // {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={2}
      direction="column"
    >
      <Grid
        item
        md={6}
        xs={12}
      >
        <InfoCard title="Bundle Info" bundle={bundle} attributes={["sku", "title", "description","quantity_available", "created_at"]} classes={classes} humanize={humanize}/>
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <ProductsCard title="Products in bundle" components={components}  attributes={["name", "quantity in 1 bundle","quantity available"]} classes={classes} humanize={humanize}/>
      </Grid>
    </Grid>
  );
};

Details.propTypes = {
  className: PropTypes.string,
};

export default Details;
