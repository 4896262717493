import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import {UID} from 'AppConstants';

const useStyles = makeStyles(theme => ({
  root: {},
  filterButton: {
    marginRight: theme.spacing(2)
  },
  content: {
    padding: 0
  },
  inner: {
    // minWidth: 1150
  },
  actions: {
    padding: theme.spacing(0, 1),
    justifyContent: 'flex-end'
  }
}));

const Results = props => {
  const { orders, searchItem, startDate, endDate } = props;

  const classes = useStyles();

  const [selectedOrders] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value === 'All' ? orders.length : event.target.value);
    setPage(0)
  };

  return (
      <Card>
        <CardHeader
          title={`Product: ${searchItem.title}. SKU:  ${searchItem.sku}`}
          subheader={`Orders created between ${startDate} and ${endDate}`}
        />
        <Divider />
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedOrders.length === orders.length}
                        color="primary"
                        indeterminate={
                          selectedOrders.length > 0 &&
                          selectedOrders.length < orders.length
                        }
                        onChange={handleSelectAll}
                      />
                    </TableCell> */}
                    <TableCell>Ref</TableCell>
                    <TableCell>Source ref</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Inbound</TableCell>
                    <TableCell>Outbound</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell align="right">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {orders.slice(page*rowsPerPage, (page+1)*rowsPerPage).map(order => (
                    <TableRow
                      key={order.id}
                      selected={selectedOrders.indexOf(order.id) !== -1}
                    >
                      {/* <TableCell padding="checkbox">
                        <Checkbox
                          checked={selectedOrders.indexOf(order.id) !== -1}
                          color="primary"
                          onChange={event => handleSelectOne(event, order.id)}
                          value={selectedOrders.indexOf(order.id) !== -1}
                        />
                      </TableCell>*/}
                      <TableCell>
                        {order.booking_id}
                        <Typography variant="body2">
                          {moment(order.created_at).format(
                            'DD MMM YYYY | hh:mm'
                          )}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {order.reference}
                        <Typography variant="body2">
                          {order.source_shop}
                        </Typography>
                      </TableCell>
                      <TableCell>{order.status}</TableCell>
                      <TableCell>{order.inbound}</TableCell>
                      <TableCell>{order.outbound}</TableCell>
                      <TableCell>{order.total}</TableCell>

                      <TableCell align="right">
                        <Button
                          color="primary"
                          component={RouterLink}
                          size="small"
                          to=
                            {{  pathname: `/user/${UID}/orders/summary/${order.booking_id}/details`,
                                state: { from: `/user/${UID}/analytics/sku_breakdown/` }
                            }}
                          variant="outlined"
                          disabled={order.booking_id === '-'}
                        >
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </PerfectScrollbar>
        </CardContent>

        <CardActions className={classes.actions}>
          <TablePagination
            component="div"
            count={orders.length}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[10, 25, 50, 'All']}
            labelRowsPerPage
          />
        </CardActions>
      </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  orders: PropTypes.array.isRequired
};

Results.defaultProps = {
  orders: []
};

export default Results;
