import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid, Button, Select } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  results: {
    marginTop: theme.spacing(3)
  },
  date: {
  },
  header: {
    cursor: 'pointer',
    padding: '15px',
  },
  content: {
    borderRadius: "6px",
    opacity: "1",
    boxShadow: "0 0 0 1px rgba(6,44,82,.1),0 2px 16px rgba(33,43,54,.08)",
    display: 'flex',
    flexDirection: 'column',
    padding: '40px',
  },
  formBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20 px, auto',
  },
  error: {
    color: '#f44336',
    padding: '5px'
  }
}));

const OrdersDatePicker = props => {
  const { handleCalendarAccept, togglePanel, startDate, endDate } = props;


  const classes = useStyles();
  const [option, setOption] = useState(365);

  const handleRangeChange = (event) => {
    event.persist();
    setOption(event.target.value);
    handleCalendarAccept(moment().subtract(event.target.value, 'days'), 'start')
    handleCalendarAccept(moment(), 'end')
  };

  const dateRanges = [
    { date: 'Today', daysAgo: 0 },
    { date: 'Yesterday', daysAgo: 1 },
    { date: 'Last 30 days', daysAgo: 30 },
    { date: 'Last year', daysAgo: 365 },
  ];

  return (
    <div className={classes.content}>
      <Select
        native
        value={option}
        onChange={handleRangeChange}
        inputProps={{
          name: 'option',
          id: 'option',
        }}
      >
        {dateRanges.map(dateRange => (
          <option value={dateRange.daysAgo} key={dateRange.daysAgo}>{dateRange.date}</option>
        ))}
      </Select>

      <Grid container justify="space-around" spacing={5} >
        <Grid item xs={12} sm={12} md={6} className={classes.formBox}>
          <KeyboardDatePicker
            disableToolbar
            variant="static"
            format="DD/MM/YYYY"
            margin="normal"
            id="date-picker-inline"
            label="Start date"
            value={startDate}
            onChange={(date) => handleCalendarAccept(date, 'start')}
            name="startDate"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.formBox}>
          <KeyboardDatePicker
            disableToolbar
            variant="static"
            format="DD/MM/YYYY"
            margin="normal"
            onChange={(date) => handleCalendarAccept(date, 'end')}
            label="End date"
            value={endDate}
            id="endDate"
            name="endDate"
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </Grid>
      </Grid>
      <Button
        onClick={togglePanel}
        variant="outlined"
        className={classes.results}
      >
        Apply
      </Button>
    </div>
  );
};

OrdersDatePicker.propTypes = {
  className: PropTypes.string
};

export default OrdersDatePicker;
