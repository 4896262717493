import React, { useState } from 'react'
import { Typography, withStyles, } from '@material-ui/core';
import { Grid, Button } from '@material-ui/core';
import Dropzone from 'components/Upload/Dropzone';
import { TextValidator } from 'react-material-ui-form-validator';
import isFractionalNumber from '../isFractionalNumber';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';

const styles = theme => ({
  imageUploadZone: {
    marginTop: '119px',
    textAlign: 'center'
  },
  form: {
    marginTop: 24
  },
  textField: {
    marginTop: '20px'
  },
  header: {
    padding: '10px',
  },
  hide: {
    display: 'none'
  },
  containerHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline'
  },
  helperText: {
    textAlign: 'right',
    padding: '10px',
    width: '100%'
  }
})

function SizesAndDimensionsForm({ item, classes, onChange, skuOtherDetails, skuSizesFields, addUnitFields, index, handleDelete, validateInput }) {
  return (
    <div className={classes.form}>
      <div className={classes.containerHeader}>
        <Typography >{item.isNew ? 'New packaging' : item.unit_type || 'BASE'}</Typography>
        {item.isNew ? <Button onClick={handleDelete(index)}>X</Button> : null}
      </div>
      <Grid container spacing={3}>
        {skuOtherDetails.map((column, i) =>
          <Grid item xs={6} sm={3} key={`d${column.value}_${index}`}>
            <TextValidator
              className={classes.textField}
              validators={validateInput && column.value === 'items_per_unit' ? validateInput(column, index) : column.required}
              errorMessages={column.errorMessages}
              id={column.value}
              name={column.value}
              label={column.label}
              fullWidth
              value={item[column.value]}
              onChange={e => onChange(e, index)}
              variant="outlined"
              disabled={item.isNew ? false : column.disabled || false}
              select={column.options ? true : false}
              SelectProps={{ native: true }}
            >

              {column.options && column.options.map(option =>
                <option value={option} >
                  {option}
                </option>
              )}

            </TextValidator>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        {skuSizesFields.map((column, i) =>
          <Grid item xs={3} sm={3} key={`${column.value}_${index}`}>
            <TextValidator
              className={classes.textField}
              validators={column.required}
              errorMessages={column.errorMessages}
              id={column.value}
              name={column.value}
              label={column.label}
              fullWidth
              value={item[column.value]}
              onChange={e => onChange(e, index)}
              variant="outlined"
              disabled={item.isNew ? false : column.disabled || false}
            />
          </Grid>
        )}
      </Grid>
    </div>
  )
}



function P1SKU(props) {

  const { classes, categories, addUnitFields, handleDelete, parentState } = props;

  const options = [{ id: '', title: '' }].concat(categories.filter(function (x) { return x.id !== "-1"; }));

  const { countries } = props;

  const skuDetailsFields =
    [
      { value: 'title', label: 'Title', required: ['required', 'maxStringLength:200'], errorMessages: ['Title is required', 'Max characters: 200'] },
      { value: 'category_id', label: 'Category', required: [] },
      { value: 'hs_code', label: 'HS Code', required: ['required'], errorMessages: ['HS Code is required'] },
      { value: 'country_of_manufacture', label: 'Country of manufacture',required: ['required'], errorMessages: ['Country Of Manufacture is required'], type: 'native-select', options: countries },
    ]

  const skuOtherDetails =
    [
      { value: 'unit_type', label: "Packaging type", width: 3, disabled: true },
      { value: 'barcode', label: "Barcode", required: ['required'], errorMessages: ['This field is required'], disabled: true },
      { value: 'sku', label: "SKU", required: ['required', 'maxStringLength:50', 'minStringLength:3'], errorMessages: ['This field is required', 'Max characters: 50', 'Min characters: 3'] },
      { value: 'items_per_unit', label: "Child units in this packaging", required: ['required', 'isPositive'], disabled: true },
    ]

  const skuOtherDetailsBase =
    [
      { value: 'barcode', label: "Barcode", required: [], errorMessages: [''], disabled: true },
      { value: 'sku', label: "SKU", required: ['required', 'maxStringLength:50', 'minStringLength:3'], errorMessages: ['This field is required', 'Max characters: 50', 'Min characters: 3'] },
    ]

  const skuSizesFields =
    [
      { value: 'length', label: 'Length [meter]', required: ['required', 'isFloat', 'minFloat:0.000001'], errorMessages: ['length is required', "That's not a number", "Must be greater than 0"], disabled: true },
      { value: 'width', label: 'Width [meter]', required: ['required', 'isFloat', 'minFloat:0.000001'], errorMessages: ['width is required', "That's not a number", "Must be greater than 0"], disabled: true },
      { value: 'height', label: 'Height [meter]', required: ['required', 'isFloat', 'minFloat:0.000001'], errorMessages: ['height is required', "That's not a number", "Must be greater than 0"], disabled: true },
      { value: 'weight', label: 'Weight [kg]', required: ['required', 'isFloat', 'minFloat:0.000001'], errorMessages: ['weight is required', "That's not a number", "Must be greater than 0"], disabled: true },
    ]

  const validateInput = (column, index) => {
    let validation = [...column.required]
    let minValue
    if (index > 0) {
      minValue = 0.000001;
      validation.push(`minFloat:${minValue}`)
      return validation
    }
  }

  const [errImgName, setErrImgName] = useState(null);

  const handleSnackbarClose = () => {
    setErrImgName(null);
  };

  return (
    <>
      <Typography variant='h6' className={classes.header}>
        SKU Details
      </Typography>
      <Grid container spacing={3} >
        <Grid item xs={12} sm={6}>
          {skuDetailsFields.map((column, i) =>
            <TextValidator
              key={`${column.value}`}
              className={classes.textField}
              validators={column.required}
              errorMessages={column.errorMessages}
              id={column.value}
              name={column.value}
              label={column.label}
              fullWidth
              value={parentState[column.value]}
              onChange={props.onChange}
              variant="outlined"
              select={column.value === 'category_id' || column.value === 'country_of_manufacture'}
              SelectProps={{ native: true }}
              disabled={column.disabled || false}
            >

              {column.value === 'category_id' ? (options.map(option => (
                <option
                  key={option.id}
                  value={option.id}
                >
                  {option.title}
                </option>
              ))) : null}

              {column.value === 'country_of_manufacture' ? (countries.map(option => (
                <option key={option.value} value={option.text}>
                  {option.text}
                </option>

              ))) : null}

            </TextValidator>
          )}

          <TextValidator
            className={classes.textField}
            id="description"
            name='description'
            label='Description'
            fullWidth
            multiline
            rowsMax='4'
            value={parentState.description}
            onChange={props.onChange}
            variant="outlined"
            validators={['required', 'maxStringLength:500']}
            errorMessages={['Description is required', 'Max characters: 500']}
          />
          <Typography variant='subtitle2'> * This description will be used on customs declarations for international shipments </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <div className={classes.imageUploadZone}>
            <div className={classes.imageFileList}>
              <Dropzone
                disabled={false}
                onFilesAdded={props.onImageUpload}
                accept="image/png,image/jpg,image/jpeg"
                preview={parentState.preview}
                errImgName={setErrImgName}
              />
              <TextValidator
                value={parentState.image}
                validators={['required']}
                errorMessages={['Image is required']}
                type='hidden'
              />
              <SuccessSnackbar
                open={Boolean(errImgName)}
                error={`Select only PNG, JPG or JPEG file`}
                onClose={handleSnackbarClose}
              />
            </div>
          </div>
        </Grid>
      </Grid>

      <SizesAndDimensionsForm
        item={parentState}
        classes={classes}
        onChange={props.onChange}
        skuOtherDetails={skuOtherDetailsBase}
        skuSizesFields={skuSizesFields}
      />

      {parentState.units ?
        parentState.units.map((unit, i) =>
          <React.Fragment key={i}>
            <SizesAndDimensionsForm
              item={unit}
              classes={classes}
              onChange={props.onNewFieldChange}
              skuOtherDetails={skuOtherDetails}
              skuSizesFields={skuSizesFields}
              index={i}
              handleDelete={handleDelete}
              validateInput={validateInput}
            />


            {i > 0 ?
              <div className={classes.helperText}>
                This packaging is equal to {unit.unitsOfLowerPackaging} x BASE units
              </div> : null
            }

          </React.Fragment>
        ) : null
      }
    </>
  )
}

export default withStyles(styles)(P1SKU);