import React, {useState} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  Button,
  TextField,
  Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import { useDispatch } from 'react-redux';
import { deleteTerminology, updateTerminology } from 'actions/terminologyActions';
import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import ConfirmAlert from 'components/Help/ConfirmAlert';


const useStyles = makeStyles(theme => ({
  card: {
    marginTop: theme.spacing(2),
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    alignItems: 'center'
  },
  description: {
    flexGrow: 1,
    display: 'flex',
    padding: theme.spacing(2),
    alignItems: 'center',
    borderTop: '1px #e3e2e2 solid',
    opactity: '0.7',
    whiteSpace: 'pre-line'
  },
  title: {
    cursor: 'pointer',
    padding: theme.spacing(2),
    flexGrow: 1,
    height: '100%'
  },
  padding: {
    padding: theme.spacing(2),
  }
}));

const Terminology = props => {
  const {error, permittedToAccess} = props
  const [collapsed, setCollapsed] = useState(true)
  const [edit, setEdit] = useState(false)
  const [terminology, setTerminology] = useState({title: props.terminology.title , details: props.terminology.details, id: props.terminology.id})
  const classes = useStyles();
  const [valid, setValid] = useState('')
  const dispatch = useDispatch();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleClick = () => {
    setCollapsed(!collapsed)
  }
  const handleEdit = () => {
    setEdit(!edit)
  }

  const handleChange = e => {
    setTerminology({...terminology, [e.target.name]: e.target.value})
  }

  const onCancel = e => {
    setTerminology(props.terminology)
    setEdit(false)
  }

  const onSubmit = e => {
    e.preventDefault();
    if (terminology.title.length === 0 || terminology.details.length === 0) {
      setValid('This field is required')
    } else {
      dispatch(updateTerminology(terminology))
      .then((response) => { if (response) {
        setOpenSnackbar(true)
      } else {
        setEdit(false)
        setOpenSnackbar(true)
      }})
    }
  }

  const handleDelete = e => {
    dispatch(deleteTerminology(terminology.id))
    setOpenSnackbar(true)
  }

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const handleAlertClose = () => {
    setOpenAlert(false);
  };

  let header

  if (edit && permittedToAccess("terminologies:edit")) {
    header =
        <div className={classes.header}>
          <TextField
              className={classes.padding}
              variant='outlined'
              id="title"
              name='title'
              value={terminology.title}
              onChange={handleChange}
              // inputProps={{ style: { fontFamily: 'Arial', color: 'red'}}}
              helperText={terminology.title.length  === 0 ? valid : ''}
          />
        </div>
  } else {
    header =
        <div className={classes.header}>
            <div className={classes.title}  onClick={handleClick}>
              {terminology.title}
            </div>
            {permittedToAccess("terminologies:edit") ? 
            <>
              <Button onClick={handleEdit}>
                <EditIcon className={classes.buttonIcon} />
              </Button>
              <Button onClick= {() => setOpenAlert(true)} >
                <DeleteIcon className={classes.buttonIcon} />
              </Button>
            </> : null}
        </div>
  }


  let detailsDiv

  if (!collapsed && !edit) {
    detailsDiv =
        <Typography className={classes.description}>
          {terminology.details}
        </Typography>
  } else if (edit && permittedToAccess("terminologies:edit")) {
    detailsDiv =
      <div className={classes.header}>
          <TextField
            variant='outlined'
            className={classes.title}
            id="details"
            name='details'
            value={terminology.details}
            onChange={handleChange}
            style= {{border: 'none', flexGrow: '1', marginRight: '20px', minWidth: '80%'}}
            multiline= {true}
            helperText={terminology.details.length  === 0 ? valid : ''}
          />

          <Button
            onClick = {onSubmit}
            color= 'primary'
          >
            Save Changes
          </Button>
          <Button
            onClick = {onCancel}
            color= 'primary'
          >
            Cancel
          </Button>
      </div>
  }



  return (
    <Card className={classes.card}>
        {header}
        {detailsDiv}
        <SuccessSnackbar
          onClose={handleSnackbarClose}
          open={openSnackbar}
          messageText={error ? null: "Changes saved!"}
          error={error? error.message : null}
        />
        <ConfirmAlert
          open = {openAlert}
          description  = "Do you really want to delete this terminology?"
          onSubmit = {handleDelete}
          onClose = {handleAlertClose}
        />
    </Card>
  );
};


export default Terminology;
