// https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/

const rules = {
  member: {
    static: [
      "overview:viewGeneral",
      "products:viewGeneral",
      "bundles:view",
      "categories:view",
      "orders:list","orders:overview","orders:chat","orders:edit","orders:create",
      "terminologies:view",
      "suppliers:view",
      "customers:view",
      "chate:view","chat:create",
    ]
  },
  admin: {
    static: [      
      "organisations:view","organisations:edit",
      "overview:viewGeneral", "overview:viewFinancials",
      "products:viewGeneral", "products:viewFinancial","products:edit","products:create",
      "bundles:view","bundles:create","bundles:edit",
      "categories:view","categories:create","categories:edit",
      "orders:list","orders:overview","orders:chat","orders:pricing","orders:edit","orders:create",
      "terminologies:view","terminologies:create","terminologies:edit",
      "navbar:analytics","analytics:view",
      "suppliers:view","suppliers:create","suppliers:edit",
      "customers:view","customers:create","customers:edit",
      "chate:view","chat:create",
      "navbar:invoices","invoices:view"
    ]
  },
  owner: {
    static: [      
      "organisations:view","organisations:edit",
      "users:invite", 
      "overview:viewGeneral", "overview:viewFinancials",
      "products:viewGeneral", "products:viewFinancial","products:edit","products:create",
      "bundles:view","bundles:create","bundles:edit",
      "categories:view","categories:create","categories:edit",
      "orders:list","orders:overview","orders:chat","orders:pricing","orders:edit","orders:create",
      "terminologies:view","terminologies:create","terminologies:edit",
      "navbar:analytics","analytics:view",
      "suppliers:view","suppliers:create","suppliers:edit",
      "customers:view","customers:create","customers:edit",
      "chate:view","chat:create",
      "navbar:invoices","invoices:view"
    ]
  }
};

export default rules;