import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core';

import { Chart } from './components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1)
    }
  },
  inner: {
    // height: 375,
    // minWidth: 500
  },
  chart: {
    height: '100%'
  }
}));

const PerformanceOverTime = props => {
  const { className, monthlyOutbounds, ...rest } = props;

  const classes = useStyles();

  const getMonths = function() {
    var monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    var d = new Date();
    d.setDate(1);
    const labels=[]
    var i
    for (i=0; i<=11; i++) {
        labels.push(monthName[d.getMonth()] + ' ' + d.getFullYear());
        d.setMonth(d.getMonth() - 1);
    }
    return labels.reverse()
  }

  const months = getMonths();

  const getOrders = function(months) {
    let orders = []
    months.forEach(month => orders.push(monthlyOutbounds[month] || 0));
    return orders
  }

  const data = {
    thisYear: {
      data: getOrders(months),
      labels: months
    }
  };

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Divider />
      <CardHeader title="Monthly outbounds"/>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
              data={data.thisYear.data}
              labels={data.thisYear.labels}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

PerformanceOverTime.propTypes = {
  className: PropTypes.string
};

export default PerformanceOverTime;
