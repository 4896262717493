import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
} from '@material-ui/core';
import Close from "@material-ui/icons/Close";

import P1SKU from './EditSteps/P1SKU';
import P2Values from './EditSteps/P2Values';
import P3Summary from './EditSteps/P3Summary'
import Notice from './Notice'
import isFractionalNumber from './isFractionalNumber'

import SuccessSnackbar from 'components/Help/SuccessSnackbar';

import { connect } from 'react-redux';
import { updateItem, createItem, setMultipleSkuError } from 'actions/spaceActions';

import { ValidatorForm } from 'react-material-ui-form-validator';
import { fetchItemById } from 'actions/spaceActions';

const styles = theme => ({
  paper: {
    position: 'relative',
    padding: '42px',
    [theme.breakpoints.down("sm")]: {
      padding: 0
    }

  },
  stepContainer: {
    padding: '24px',
  },
  textCenter: {
    textAlign: 'center'
  },
  backButton: {
    position: 'absolute',
    left: 26,
    bottom: 10,
    marginBottom: '10px'
  },
  nextButton: {
    position: 'absolute',
    right: 26,
    bottom: 10,
    marginBottom: '10px'
  },
  buttons: {
    padding: '20px',
    [theme.breakpoints.down("sm")]: {
      padding: 28
    }
  },
  closeBtn: {
    float: 'right'
  }
});


class SKUEditForm extends React.Component {

  constructor(props) {
    super(props);
    this.steps = ['SKU Details', 'Values', 'Summary'];
    this.state = {
      activeStep: 0,
      requestStatus: '',
      finishedUpdateing: false,
      // Base unit
      id: '',
      category_id: (props.skuEdit) ? props.skuEdit.category_id : '',
      category: (props.skuEdit) ? props.skuEdit.category : '',
      image: (props.skuEdit.image) ? props.skuEdit.image.url : [],
      preview: (props.skuEdit.image) ? props.skuEdit.image.url : '',
      unit_type: '',
      items_per_unit: '',
      title: '',
      description: '',
      hs_code: '',
      country_of_manufacture: '',
      sku: '',
      barcode: '',
      length: '',
      width: '',
      height: '',
      weight: '',
      supplier_cost: '',
      retail_cost: '',
      low_stock_alert: '',
      // Other packaging units
      units: [],
      volumeError: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleBack = this.handleBack.bind(this)
    this.handleNext = this.handleNext.bind(this)
    this.onImageUpload = this.onImageUpload.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.appendPackegingData = this.appendPackegingData.bind(this)
    this.onNewFieldChange = this.onNewFieldChange.bind(this)
    this.handleDelete = this.handleDelete.bind(this)
  }

  getStepContent(step) {

    switch (step) {

      case 0:
        return <P1SKU
          setUnitsError={this.setUnitsError}
          onChange={this.handleChange}
          onImageUpload={this.onImageUpload}
          parentState={this.state}
          categories={this.props.categories}
          handleDelete={this.handleDelete}
          addUnitFields={this.appendPackegingData}
          onNewFieldChange={this.onNewFieldChange}
          countries={this.props.countries}
        />;
      case 1:
        return <P2Values
          onChange={this.handleChange}
          parentState={this.state}
          onNewFieldChange={this.onNewFieldChange}
        />;
      case 2:
        return <P3Summary
          onChange={this.handleChange}
          parentState={this.state}
          error={this.props.multipleSkuError}
          categories={this.props.categories}
        />;
      default:
        throw new Error('Unknown step');
    }
  }


  /**
   * 
   * @param {*} length 
   * @param {*} width 
   * @param {*} height 
   * @returns voulume of packaging
   */
  getVolume = (length, width, height) => {
    return length * width * height;
  }

  /**
   * 
   * @param {*} currentUnit 
   * @param {*} parentUnit 
   * @returns true if current voulume is less than immediate previous volume 
   */

  checkCubicVolume = (currentUnit, parentUnit) => {
    let currentUnitVoume = this.getVolume(currentUnit["length"], currentUnit["height"], currentUnit["width"]);
    let parentUnitVolume = this.getVolume(parentUnit["length"], parentUnit["height"], parentUnit["width"]);

    return currentUnitVoume < parentUnitVolume;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value,
    })
  }

  setUnitsError = event => {
    this.setState({
      unitsError: true,
    })
  }

  onNewFieldChange = (e, index) => {
    let units = [...this.state.units]
    units[index][e.target.name] = e.target.value
    units = this.calculateParentUnits(units);
    this.setState({ units });
  }

  handleDelete = i => e => {
    e.preventDefault()
    let newUnits = [
      ...this.state.units.slice(0, i),
      ...this.state.units.slice(i + 1)
    ]
    this.setState({
      units: newUnits
    });
  }

  goToNextStep = e => {
    var activeStep = this.state.activeStep + 1
    this.setState({
      activeStep: activeStep
    })
  }

  getVoulume(length, width, height) {
    return length * width * height;
  }

  handleNext = e => {
    e.preventDefault();
    if (this.state.activeStep + 1 === this.steps.length) {
      this.submitForm();
    } else {
      this.goToNextStep();
    }
  };


  handleBack = () => {
    this.setState({ finishedUpdateing: false })
    this.props.dispatch(setMultipleSkuError(null))

    this.setState({
      activeStep: this.state.activeStep - 1
    })
  };

  calculateParentUnits(array) {
    array.forEach((u, index) => {
      if (index > 0) {
        let numberOfParentUnits = array[index]['items_per_unit']
        numberOfParentUnits = numberOfParentUnits * array[index - 1]['items_per_unit'];
        array[index]['unitsOfLowerPackaging'] = numberOfParentUnits;
      }
    })
    return array
  }


  appendPackegingData(packaging) {
    // find base product
    if (packaging && packaging.title && (packaging.unit_type === '' || packaging.unit_type === null || packaging.unit_type === undefined || packaging.unit_type.toUpperCase() === "BASE" || packaging.unit_type.toUpperCase() === "MARKETING")) {
      this.setState({
        ...this.state,
        id: packaging.id || '',
        unit_type: '',
        items_per_unit: '',
        title: packaging.title || '',
        description: packaging.description || '',
        hs_code: packaging.hs_code || '',
        country_of_manufacture: packaging.country_of_manufacture || '',
        sku: packaging.sku || '',
        barcode: packaging.barcode || '',
        length: packaging['length'] || '',
        width: packaging['width'] || '',
        height: packaging['height'] || '',
        weight: packaging['weight'] || '',
        supplier_cost: packaging['supplier_cost'] || '',
        retail_cost: packaging['retail_cost'] || '',
        low_stock_alert: packaging['low_stock_alert'] || '',

      })
    }
    // add all other packaging types
    else {
      let unitOfItem = {
        id: (packaging) ? packaging.id : '',
        unit_type: (packaging) ? packaging.unit_type : '',
        items_per_unit: (packaging) ? packaging.items_per_unit : '',
        sku: (packaging) ? packaging.sku : '',
        barcode: (packaging) ? packaging.barcode : '',
        length: (packaging) ? packaging.length : '',
        width: (packaging) ? packaging.width : '',
        height: (packaging) ? packaging.height : '',
        weight: (packaging) ? packaging.weight : '',
        supplier_cost: (packaging) ? packaging.supplier_cost : '',
        retail_cost: (packaging) ? packaging.retail_cost : '',
        low_stock_alert: (packaging) ? packaging.low_stock_alert : '',
        unitsOfLowerPackaging: null
      }
      let newUnits

      if (packaging && packaging.title === undefined && packaging.unit_type === undefined) {
        unitOfItem.isNew = true
        // put newUnit at the end of array
        newUnits = [...this.state.units, unitOfItem]
      } else {
        // sort from smallest to the biggest
        newUnits = [...this.state.units, unitOfItem].sort((a, b) => a.items_per_unit > b.items_per_unit)
      }

      this.setState({
        units: this.calculateParentUnits(newUnits)
      })
    }
  }

  componentDidMount() {
    this.props.dispatch(fetchItemById(this.props.skuEdit['id']))
      .then(fetchedItem => {
        this.appendPackegingData(fetchedItem);
        fetchedItem.other_packaging_types.forEach(packaging => this.appendPackegingData(packaging));
      })
  }

  onImageUpload(files) {
    let reader = new FileReader();
    let file = files[0]
    reader.onloadend = () => (
      this.setState(prevState => ({
        image: files,
        preview: reader.result
      }))
    );
    if (file) {
      reader.readAsDataURL(file);
    }

  }

  submitForm() {
    var formDataBaseUnit = new FormData();
    Object.keys(this.state).forEach(attribute => {
      if (attribute === 'image' || attribute === 'preview' || attribute === 'activeStep' || attribute === 'units') return;
      formDataBaseUnit.append(attribute, this.state[attribute]);
    });

    if (this.state.image[0].name) {
      formDataBaseUnit.append('image', this.state.image[0], this.state.image[0].name)
    }

    // update the 'base' item
    this.props.dispatch(updateItem(formDataBaseUnit, this.state.id))
      .then(result => {

        if (this.state.units.length === 0)
          this.setState({ finishedUpdateing: true }, function () { console.log('finished') })

        if (!result) {
          this.props.dispatch(setMultipleSkuError(this.props.updateError))
        }

        if (this.state.units.length > 0) {
          this.state.units.map((unit, index) => {
            var formDataOtherUnit = new FormData();
            formDataOtherUnit.append('category_id', this.state.category_id || '')
            formDataOtherUnit.append('description', this.state.description)
            formDataOtherUnit.append('title', `${this.state.title} ${unit.unit_type}`)
            formDataOtherUnit.append('sku', unit.sku)
            formDataOtherUnit.append('barcode', unit.barcode)
            formDataOtherUnit.append('length', unit.length)
            formDataOtherUnit.append('width', unit.width)
            formDataOtherUnit.append('height', unit.height)
            formDataOtherUnit.append('weight', unit.weight)
            formDataOtherUnit.append('supplier_cost', unit.supplier_cost)
            formDataOtherUnit.append('retail_cost', unit.retail_cost || '')
            formDataOtherUnit.append('low_stock_alert', unit.low_stock_alert || '')

            if (this.state.image[0].name) {
              formDataOtherUnit.append('image', this.state.image[0], this.state.image[0].name)
            }

            if (unit.isNew) {
              // create other units of this item
              let parent_id = index > 0 ? this.state.units[index - 1].id : this.state.id
              formDataOtherUnit.append('parent_id', parent_id)

              this.props.dispatch(createItem(formDataOtherUnit))
                .then(response => {
                  if (index === this.state.units.length - 1)
                    this.setState({ finishedUpdateing: true }, function () { console.log('finished') });
                  if (!response) {
                    // set multipleSkuError if any of skus is errored
                    this.props.dispatch(setMultipleSkuError(this.props.updateError))
                    return
                  }
                })

            } else {
              // update other units of this item
              this.props.dispatch(updateItem(formDataOtherUnit, unit.id))
                .then(response => {
                  if (index === this.state.units.length - 1)
                    this.setState({ finishedUpdateing: true }, function () { console.log('finished') });
                  if (!response) {
                    // set multipleSkuError if any of skus is errored
                    this.props.dispatch(setMultipleSkuError(this.props.updateError))
                    return
                  }
                })
            }
          })
        }
      })
  }

  render() {
    const { classes, handleClose, updateError, multipleSkuError } = this.props;

    if (this.state.finishedUpdateing && multipleSkuError === null && updateError === null && this.props.uploading === false && this.props.updating === false && this.state.activeStep === this.steps.length - 1) {
      this.goToNextStep();
      setTimeout(() => {
        this.props.handleClose();
      }, 1000);
    }

    let errorText = (updateError && updateError.response) ? updateError.response.statusText : null;
    if (!errorText && updateError) {
      errorText = updateError.message;
    }
    return (
      <Paper className={classes.paper}>
        <SuccessSnackbar
          open={Boolean(multipleSkuError)}
          error={(multipleSkuError && multipleSkuError.response) ? multipleSkuError.response.statusText : null}
        />

        <SuccessSnackbar
          open={Boolean(updateError)}
          error={errorText || null}
        />

        <Button onClick={handleClose} className={classes.closeBtn}>
          <Close />
        </Button>

        <Typography component="h1" variant="h4" align='center'>
          SKU {this.state.sku}
        </Typography>
        <Stepper activeStep={this.state.activeStep}>
          {this.steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className={classes.stepContainer}>
          {(this.props.uploading || this.props.updating) ?
            (
              <Notice sub1="Saving items." sub2="This may take a while." progressBar={true} />
            ) : this.state.activeStep === this.steps.length ? (
              <Notice sub1="SKU has been edited succesfully!" sub2="Window will close shortly" progressBar={false} />
            ) : (
              <>
                <ValidatorForm
                  ref="form"
                  onSubmit={this.handleNext}
                  onError={errors => console.log(errors)}
                >
                  {this.getStepContent(this.state.activeStep)}
                  <div className={classes.buttons}>
                    {this.state.activeStep !== 0 && (
                      <Button
                        variant="contained"
                        onClick={this.handleBack}
                        className={classes.backButton}
                      >
                        Back
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.nextButton}
                      aria-label="go to next step"
                      type="submit"
                    >
                      {this.state.activeStep === this.steps.length - 1 ? 'Update Item' : 'Next'}
                    </Button>
                  </div>
                </ValidatorForm>
              </>
            )
          }
        </div>
      </Paper>

    )
  }
}

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  uploading: state.spaceReducer.uploading,
  updating: state.spaceReducer.updating,
  updateError: state.spaceReducer.error,
  multipleSkuError: state.spaceReducer.multipleSkuError,
  countries : state.adminAuthReducer.countries,
  categories: (state.categoriesReducer.categories.length) ? state.categoriesReducer.categories : state.spaceReducer.categoriesFilter
})

export default connect(mapStateToProps)(withStyles(styles)(SKUEditForm));