import keepspaceApi from 'services/keepspaceApi';

export const FETCH_BUNDLES_BEGIN = 'FETCH_BUNDLES_BEGIN';
export const FETCH_BUNDLES_SUCCESS = 'FETCH_BUNDLES_SUCCESS';
export const FETCH_BUNDLES_FAILURE = 'FETCH_BUNDLES_FAILURE'

export const CREATE_BUNDLE_BEGIN = 'CREATE_BUNDLE_BEGIN';
export const CREATE_BUNDLE_SUCCESS = 'CREATE_BUNDLE_SUCCESS';
export const CREATE_BUNDLE_FAILURE = 'CREATE_BUNDLE_FAILURE';

export const FETCH_BUNDLE_COMPONENTS_BEGIN = 'FETCH_BUNDLE_COMPONENTS_BEGIN'
export const FETCH_BUNDLE_COMPONENTS_SUCCESS = 'FETCH_BUNDLE_COMPONENTS_SUCCESS'
export const FETCH_BUNDLE_COMPONENTS_FAILURE = 'FETCH_BUNDLE_COMPONENTS_FAILURE'

export const FETCH_BUNDLE_HISTORY_BEGIN = 'FETCH_BUNDLE_HISTORY_BEGIN'
export const FETCH_BUNDLE_HISTORY_SUCCESS = 'FETCH_BUNDLE_HISTORY_SUCCESS'
export const FETCH_BUNDLE_HISTORY_FAILURE = 'FETCH_BUNDLE_HISTORY_FAILURE'

export const SET_SEARCH_TERM = 'SET_SEARCH_TERM'

export function fetchBundles() {
    return dispatch => {
        dispatch(fetchBundlesBegin());
        return keepspaceApi.getBundles()
            .then(json => {
                dispatch(fetchBundlesSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(fetchBundlesFailure(error))
            });
    };
}

export function createBundle(bundleDetails, components) {
    return dispatch => {
        dispatch(createBundleBegin());
        let res = keepspaceApi.createBundle(bundleDetails, components)
            .then(json => {
                dispatch(createBundleSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(createBundleFailure(error));
            })
        return res;
    }
}

export function fetchBundleComponents(bundleId) {
    return dispatch => {
        dispatch(fetchBundleComponentsBegin());
        let res = keepspaceApi.fetchBundleComponents(bundleId)
            .then(json => {
                dispatch(fetchBundleComponentsSuccess(json));
                return json;
            })
            .catch(error => {
              console.log('error', error)
                dispatch(fetchBundleComponentsFailure(error));
            })
        return res;
    }
}

export function fetchBundleHistory(bundleId) {
    return dispatch => {
        dispatch(fetchBundleHistoryBegin());
        let res = keepspaceApi.fetchBundleHistory(bundleId)
            .then(json => {
                dispatch(fetchBundleHistorySuccess(json));
                return json;
            })
            .catch(error => {
              console.log('error', error)
                dispatch(fetchBundleHistoryFailure(error));
            })
        return res;
    }
}

export const fetchBundlesBegin = () => ({
    type: FETCH_BUNDLES_BEGIN
});

export const fetchBundlesSuccess = bundles => ({
    type: FETCH_BUNDLES_SUCCESS,
    payload: { bundles }
});

export const fetchBundlesFailure = error => ({
    type: FETCH_BUNDLES_FAILURE,
    payload: { error }
});

export const createBundleBegin = () => ({
    type: CREATE_BUNDLE_BEGIN,
})


export const createBundleSuccess = bundle => ({
    type: CREATE_BUNDLE_SUCCESS,
    payload: { bundle }
})

export const createBundleFailure = error => ({
    type: CREATE_BUNDLE_FAILURE,
    payload: { error }
})

export const fetchBundleComponentsBegin = () => ({
    type: FETCH_BUNDLE_COMPONENTS_BEGIN,
})

export const fetchBundleComponentsSuccess = components => ({
    type: FETCH_BUNDLE_COMPONENTS_SUCCESS,
    payload: { components }
})

export const fetchBundleComponentsFailure = error => ({
    type: FETCH_BUNDLE_COMPONENTS_FAILURE,
    payload: { error }
})

export const fetchBundleHistoryBegin = () => ({
    type: FETCH_BUNDLE_HISTORY_BEGIN,
})

export const fetchBundleHistorySuccess = history => ({
    type: FETCH_BUNDLE_HISTORY_SUCCESS,
    payload: { history }
})

export const fetchBundleHistoryFailure = error => ({
    type: FETCH_BUNDLE_HISTORY_FAILURE,
    payload: { error }
})

export function setSearchTerm(term) {
    return { type: SET_SEARCH_TERM, term };
};
