import {
    FETCH_ITEMS_BEGIN,
    FETCH_ITEMS_SUCCESS,
    FETCH_ITEMS_FAILURE,
    CREATE_ITEM_BEGIN,
    CREATE_ITEM_SUCCESS,
    CREATE_ITEM_FAILURE,
    CLEAR_PRODUCTS,
    SET_SEARCH_ACTIVE,
    SET_SEARCH_TERM,
    SEARCH_ORDER_BY_SKU_SUCCESS,
    SEARCH_ORDER_BY_SKU_FAILURE,
    // ARCHIVE_ITEM_BEGIN,
    // ARCHIVE_ITEM_SUCCESS,
    // ARCHIVE_ITEM_FAILURE,
    UPDATE_ITEM_BEGIN,
    UPDATE_ITEM_SUCCESS,
    UPDATE_ITEM_FAILURE,
    SET_MULTIPLE_SKU_ERROR,
    UPDATE_ARCHIVED_FILTER,
    UPDATE_AVAILABLE_FILTER,
    UPDATE_LOW_STOCK_FILTER,
    FETCH_ITEM_BY_ID_BEGIN,
    FETCH_ITEM_BY_ID_SUCCESS,
    FETCH_ITEM_BY_ID_FAILURE,
    UPDATE_PER_PAGE_COUNT,
    UPDATE_PAGE_NUMBER,
    UPDATE_CATEGORY_SELECTION
} from 'actions/spaceActions';

import {applySKUInterface} from 'Interfaces';


const initialState = {
    items: [],
    loading: false,
    uploading: false,
    updating: false,
    error: null,
    multipleSkuError: null,

    itemsSearch: [],
    searchTerm: '',
    searchActive: false,

    itemStocks: [],
    monthlyOutbounds: {},
    orderBySkuError: null,
    itemSku: null,

    fetchedItem: {},

    archivedFilter: {
        yes: false,
        no: true,
        any: false
    },
    onHandFilter: {
        yes: false,
        no: false,
        any: true
    },
    availableFilter: {
        yes: false,
        no: false,
        any: true
    },
    lowStockFilter: false,

    perPage: 25,
    totalCount: 1,
    page: 1,
    totalPages: 1,
    categories: [],
    categoriesFilter: [],
    pagination: {}
}

export default function spaceReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_ITEMS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_ITEMS_SUCCESS:

            if(!state.categoriesFilter.length) {
                let newArray = [...action.payload.categories, {
                    value: '-1',
                    text: 'No Category',
                    isChecked: false
                }];
                return {
                    ...state,
                    loading: false,
                    items: action.payload.products.map(item => ({
                        ...applySKUInterface(item)
                    })),
                    perPage: action.payload.perPage,
                    page: action.payload.page,
                    pagination: action.payload.pagination,
                    totalCount: action.payload.totalCount,
                    totalPages: action.payload.totalPages,
                    categories: action.payload.categories,
                    categoriesFilter: newArray.map(category => {

                        return {
                            id: category.value,
                            title: category.text,
                            isChecked: false
                        }
                    })
                };
            }
            else {
                return {
                    ...state,
                    loading: false,
                    items: action.payload.products.map(item => ({
                        ...applySKUInterface(item)
                    })),
                    perPage: action.payload.perPage,
                    page: action.payload.page,
                    pagination: action.payload.pagination,
                    totalCount: action.payload.totalCount,
                    totalPages: action.payload.totalPages,
                    categories: action.payload.categories
                };
            }


        case FETCH_ITEMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                items: []
            };
        case CREATE_ITEM_BEGIN:
            return {
                ...state,
                uploading: true,
                error: null
            }
        case CREATE_ITEM_SUCCESS:

            let newItems = action.payload.items.map(item => applySKUInterface(item)).sort((a, b) => a.id < b.id)
            return {
                ...state,
                uploading: false,
                error: null,
                // Add the new item to the items array, after applying the interface of course
                items: [...newItems, ...state.items]
            }
        case CREATE_ITEM_FAILURE:
            let error = {}
            if(action.payload.error.response && action.payload.error.response.status >= 400 &&
                !action.payload.error.response.statusText) {
                if(action.payload.error.response.data) {
                    error = {...action.payload.error.response.data}
                }
                else {
                    error.message = "An error occurred, please try again later";
                }
            }

            return {
                ...state,
                uploading: false,
                error: error.message ? error : action.payload.error,
            }
        case CLEAR_PRODUCTS:
            return {
                ...state,
                items: [],
                perPage: 25,
                page: 1,
                totalCount: 0,
                totalPages: 1,
                categories: [],
                categoriesFilter: []
            }
        case SET_SEARCH_ACTIVE:
            return {
                ...state,
                searchActive: action.active
            }
        case SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.term
            }

        case UPDATE_PAGE_NUMBER:
            return {
                ...state,
                page: action.payload.pageNo
            }

        case UPDATE_PER_PAGE_COUNT:
            return {
                ...state,
                perPage: action.payload.count
            }
        case SEARCH_ORDER_BY_SKU_SUCCESS:
            return {
                ...state,
                itemStocks: action.payload.itemStocks,
                monthlyOutbounds: action.payload.monthlyOutbounds || {},
                itemSku: action.payload.itemSku,
                orderBySkuError: null,
                loading: false,
            }
        case SEARCH_ORDER_BY_SKU_FAILURE:
            return {
                ...state,
                orderBySkuError: action.payload.error,
                monthlyOutbounds: {},
                itemStocks: [],
                itemSku: null,
                loading: false,
            }

        case UPDATE_ITEM_BEGIN:
            return {
                ...state,
                updating: true,
                error: null,
            }

        case UPDATE_ITEM_SUCCESS:
            let updatedItems = state.items.map(item => item.id === action.payload.item.id ?
                {...item, ...action.payload.item} : item  // This spread syntax was used in order to fill in any missing fields with the existing items' copy (such as images that aren't always returned)
            )
            return {
                ...state,
                items: updatedItems,
                updating: false,
                error: null,
                fetchedItem: action.payload.item
            }

        case UPDATE_ITEM_FAILURE:
            let errors = {}
            if(action.payload.error.response && action.payload.error.response.status >= 400 &&
                !action.payload.error.response.statusText) {
                if(action.payload.error.response.data) {
                    error = {...action.payload.error.response.data}
                }
                else {
                    error.message = "An error occurred, please try again later";
                }
            }
            return {
                ...state,
                updating: false,
                error: errors.message ? errors : action.payload.error,
            };
        case UPDATE_ARCHIVED_FILTER:
            return {
                ...state,
                archivedFilter: {
                    yes: action.property === 'yes' ? action.value : false,
                    any: action.property === 'any' ? action.value : false,
                    no: action.property === 'no' ? action.value : false
                }
            };
        case UPDATE_AVAILABLE_FILTER:
            Object.keys(state[action.property]).forEach(v => (v === action.value ? state[action.property][v] = action.isChecked : state[action.property][v] = false))
            return {
                ...state,
                [action.property]: state[action.property]
            };
        case UPDATE_LOW_STOCK_FILTER:
            return {
                ...state,
                lowStockFilter: action.isChecked
            };

        case FETCH_ITEM_BY_ID_BEGIN:
            return {
                ...state,
                childLoading: true,
                error: null
            };


        case FETCH_ITEM_BY_ID_SUCCESS:
            return {
                ...state,
                childLoading: false,
                fetchedItem: action.payload.item,
            };

        case FETCH_ITEM_BY_ID_FAILURE:
            return {
                ...state,
                childLoading: false,
                error: action.payload.error,
            };
        case SET_MULTIPLE_SKU_ERROR:
            return {
                ...state,
                multipleSkuError: action.payload.error,
            };
        case UPDATE_CATEGORY_SELECTION:
            let updatedCategoryFilters = state.categoriesFilter.map(category_filter => category_filter.id === action.value ?
                {id: category_filter.id, title: category_filter.title, isChecked: action.isChecked} : category_filter
            )
            return {
                ...state,
                categoriesFilter: updatedCategoryFilters
            };

        default:
            // Always have a default case in case a reducer doesn't complete
            return state;
    }
};
