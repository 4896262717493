import {
    FETCH_TERMINOLOGIES_BEGIN,
    FETCH_TERMINOLOGIES_SUCCESS,
    FETCH_TERMINOLOGIES_FAILURE,

    CREATE_TERMINOLOGY_BEGIN,
    CREATE_TERMINOLOGY_SUCCESS,
    CREATE_TERMINOLOGY_FAILURE,

    DELETE_TERMINOLOGY_SUCCESS,
    DELETE_TERMINOLOGY_FAILURE,

    UPDATE_TERMINOLOGY_SUCCESS,
    UPDATE_TERMINOLOGY_FAILURE,

} from 'actions/terminologyActions';


const initialState = {
    terminologies: [],
    loading: false,
    error: null,
}

export default function usersReducer(state = initialState, action) {
    switch(action.type) {
        case FETCH_TERMINOLOGIES_BEGIN:
            return  {
                ...state,
                loading: true,
                error: null
            };
        case FETCH_TERMINOLOGIES_SUCCESS:
            return  {
                ...state,
                loading: false,
                terminologies: action.payload.terminologies
            };
        case FETCH_TERMINOLOGIES_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case CREATE_TERMINOLOGY_BEGIN:
            return  {
                ...state,
                loading: true,
                error: null
            };
        case CREATE_TERMINOLOGY_SUCCESS:
            return  {
                ...state,
                loading: false,
                terminologies: [action.payload.terminology, ...state.terminologies]
            };
        case CREATE_TERMINOLOGY_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case DELETE_TERMINOLOGY_SUCCESS:
            return  {
                ...state,
                loading: false,
                terminologies: state.terminologies.filter(terminology => terminology.id !== action.payload.id),
            };
        case DELETE_TERMINOLOGY_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case UPDATE_TERMINOLOGY_SUCCESS:
            let updatedTerminologies = state.terminologies.map(terminology => terminology.id === action.payload.terminology.id ?
                action.payload.terminology : terminology
            )
            return {
                ...state,
                terminologies: updatedTerminologies,
                loading: false,
                error: false
            }
        case UPDATE_TERMINOLOGY_FAILURE:
            return  {
                ...state,
                loading: false,
                error: action.payload.error,
        };
        default:
            return state
    }
}
