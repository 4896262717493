import { apiPath, authHeaders } from 'AppConstants';
import { apiErrorHandler } from 'utils/apiErrorHandler';

export const FETCH_TERMINOLOGIES_BEGIN = 'FETCH_TERMINOLOGIES_BEGIN';
export const FETCH_TERMINOLOGIES_SUCCESS = 'FETCH_TERMINOLOGIES_SUCCESS';
export const FETCH_TERMINOLOGIES_FAILURE = 'FETCH_TERMINOLOGIES_FAILURE'

export const DELETE_TERMINOLOGY_BEGIN = 'DELETE_TERMINOLOGY_BEGIN';
export const DELETE_TERMINOLOGY_SUCCESS = 'DELETE_TERMINOLOGY_SUCCESS';
export const DELETE_TERMINOLOGY_FAILURE = 'DELETE_TERMINOLOGY_FAILURE'

export const CREATE_TERMINOLOGY_BEGIN = 'CREATE_TERMINOLOGY_BEGIN';
export const CREATE_TERMINOLOGY_SUCCESS = 'CREATE_TERMINOLOGY_SUCCESS';
export const CREATE_TERMINOLOGY_FAILURE = 'CREATE_TERMINOLOGY_FAILURE'

export const UPDATE_TERMINOLOGY_SUCCESS = 'UPDATE_TERMINOLOGY_SUCCESS';
export const UPDATE_TERMINOLOGY_FAILURE = 'UPDATE_TERMINOLOGY_FAILURE'


export function fetchTerminologies() {
    return dispatch => {
        dispatch(fetchTerminologiesBegin());
        return fetch(
            (apiPath + '/terminologies/'),
            {
                method: 'GET',
                headers: authHeaders
            }
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(fetchTerminologiesSuccess(json));
                return json;
            })
            .catch(error => {
                console.log(error)
                dispatch(fetchTerminologiesFailure(error))
            });
    };
}

function handleErrors(response) {
    if (!response.ok) {
        apiErrorHandler(response)
        throw Error(response.statusText);
    }
    return response;
}

export const fetchTerminologiesBegin = () => ({
    type: FETCH_TERMINOLOGIES_BEGIN
});

export const fetchTerminologiesSuccess = terminologies => ({
    type: FETCH_TERMINOLOGIES_SUCCESS,
    payload: { terminologies }
});

export const fetchTerminologiesFailure = error => ({
    type: FETCH_TERMINOLOGIES_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});

export function deleteTerminology(id) {
    return dispatch => {
        // dispatch(deleteTerminologyBegin());
        return fetch(
            (apiPath + '/terminologies/' + id),
            {
                method: 'DELETE',
                headers: authHeaders
            }
        )
            .then(handleErrors)
            .then(json => {
                dispatch(deleteTerminologySuccess(id));
            })
            .catch(error => {
                console.log('error', error)
                // dispatch(deleteTerminologyFailure(error))
            });
    };
}
export const deleteTerminologySuccess = id => ({
    type: DELETE_TERMINOLOGY_SUCCESS,
    payload: { id }
});

export const deleteTerminologyFailure = error => ({
    type: DELETE_TERMINOLOGY_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});


export function createTerminology(terminology) {
    return dispatch => {
        dispatch(createTerminologyBegin());
        return fetch(
            (apiPath + '/terminologies/'),
            {
                method: 'POST',
                headers: {
                    ...authHeaders,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ terminology: terminology }),
            }
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(createTerminologySuccess(json));
            })
            .catch(error => {
                dispatch(createTerminologyFailure(error))
            });
    };
}

export const createTerminologyBegin = () => ({
    type: CREATE_TERMINOLOGY_BEGIN
});

export const createTerminologySuccess = terminology => ({
    type: CREATE_TERMINOLOGY_SUCCESS,
    payload: { terminology }
});

export const createTerminologyFailure = error => ({
    type: CREATE_TERMINOLOGY_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});

export function updateTerminology(terminology) {
    return dispatch => {
        // dispatch(deleteTerminologyBegin());
        return fetch(
            (apiPath + '/terminologies/' + terminology.id),
            {
                method: 'PUT',
                //credentials: 'include',
                headers: {
                    ...authHeaders,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ terminology: terminology }),
            }
        )
            .then(handleErrors)
            .then(res => res.json())
            .then(json => {
                dispatch(updateTerminologySuccess(json));
            })
            .catch(error => {
                dispatch(updateTerminologyFailure(error))
                return error
            });
    }
}

export const updateTerminologySuccess = (terminology) => ({
    type: UPDATE_TERMINOLOGY_SUCCESS,
    payload: { terminology }
})

export const updateTerminologyFailure = error => ({
    type: UPDATE_TERMINOLOGY_FAILURE,
    payload: { error: (error.response) ? error.response.data : 'Something went wrong. Please try again' }
});
