import React, { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Paper,
} from '@material-ui/core';
import {UID} from 'AppConstants';

const styles = theme => ({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
    table: {
      minWidth: 700,
      padding: '20px'
    },
    row: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      }
    },
    nameCell: {
      display: 'flex',
      alignItems: 'center'
    },
    avatar: {
      height: 42,
      width: 42,
      marginRight: theme.spacing(1),
    },
  });

const columns =
    [
      {label: 'Name', name: 'name'},
      {label: 'Company', name: 'company_name'},
      {label: 'Country', name: 'country'},
      {label: 'Type', name: 'customer_type'},
      {label: 'Total sold', name: 'total_sold', nested: true, prefix: '$ '},
      {label: 'Orders', name: 'orders', nested: true},
      {label: 'Actions', name: 'actions'},
    ]

export function CustomersTable (props) {

  const { classes, customers } = props;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const cellValue = (customer, column) => {
    let prefix = column.prefix || ""
    let value = column.nested ? customer.details[column.name] : customer[column.name]
    return value ? prefix + value : "-"
  }

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value === 'All' ? customers.length : event.target.value);
    setPage(0)
  };
    return (
      <Paper className={classes.root}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
                {columns.map(column => (
                  <TableCell key={column.name}  style={{paddingLeft: column.name === 'name' ?  '66px' : ''}}>{column.label}</TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {customers.slice(page*rowsPerPage, (page+1)*rowsPerPage).map((customer) => (
              <TableRow
                key={customer.id}
                hover
              >
                  <TableCell>
                    <div className={classes.nameCell}>
                      <Avatar
                        className={classes.avatar}
                        src={customer.avatar || `https://robohash.org/${customer.id}`}
                      >
                      </Avatar>
                      <div>
                        {customer.name}
                        <div>{customer.email}</div>
                      </div>
                    </div>
                  </TableCell>
                  {columns.slice(1, columns.length-1).map(column => (
                      <TableCell key={column.name}>{cellValue(customer, column)}</TableCell>
                  ))}
                  <TableCell>
                    <Link to={`/user/${UID}/manage/customers/${customer.id}`} >
                      View
                    </Link>
                  </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={customers.length}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[10, 25, 'All']}
        />
      </Paper>
    );
}

CustomersTable.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default memo(withStyles(styles)(CustomersTable));
