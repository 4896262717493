import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import SuppliersDetail from 'views/user/Suppliers/SuppliersDetail/SuppliersDetail';
import SuppliersIndex from 'views/user/Suppliers/SuppliersIndex';
import SuppliersAdd from 'views/user/Suppliers/SuppliersAdd';

import LinearProgress from '@material-ui/core/LinearProgress';

import { connect } from 'react-redux';
import { fetchSuppliers } from 'actions/supplierActions';
import { withStyles } from '@material-ui/core';

import SuccessSnackbar from 'components/Help/SuccessSnackbar';
import checkPermission from "components/checkPermission";

class Suppliers extends Component {
  _isMounted = false
  constructor(props) {
    super(props);
    this.state = {
      countries: props.countries || []
    }
    this.LinearIndeterminate = this.LinearIndeterminate.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    if (!this.props.loading) {
      if (this.props.suppliers.length === 0) {
        this.props.dispatch(fetchSuppliers());
      }
    }
    
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  LinearIndeterminate(props) {
    //const { classes } = props;
    return (
      <div>
        <LinearProgress />
        <br />
        <LinearProgress color='secondary' />
      </div>
    );
  }

  permittedToAccess = (page) => {
    return checkPermission(this.props.currentOrganisation.currentOrganisationUser.role, page)
  }

  render() {
    return (
      <div>
        <SuccessSnackbar
          open={this.props.error !== null}
          error={(this.props.error && this.props.error.response) ? this.props.error.response.statusText : null}
        />

        {this.props.loading ? (
          <this.LinearIndeterminate />
        ) : (

          <>
            <Route path='/user/:uid/manage/suppliers/' exact render={(routeProps) => <SuppliersIndex suppliers={this.props.suppliers} {...routeProps} permittedToAccess={this.permittedToAccess} />} />
            <Route path='/user/:uid/manage/suppliers/:id(\d+)/' exact={true} component={SuppliersDetail} />
            <Route path='/user/:uid/manage/suppliers/:id(\d+)/:tab' exact={true} render={(routeProps) => <SuppliersDetail countries={this.state.countries} {...this.props} {...routeProps} permittedToAccess={this.permittedToAccess} />} />
            <Route path='/user/:uid/manage/suppliers/add' exact render={(routeProps) => <SuppliersAdd {...routeProps} countries={this.state.countries} {...this.props} permittedToAccess={this.permittedToAccess} />} />
          </>
        )
        }
      </div>
    )
  }
}

const styles = theme => ({

})


const mapStateToProps = state => ({
  suppliers: state.suppliersReducer.suppliers,
  loading: state.suppliersReducer.loading,
  error: state.suppliersReducer.error,
  currentOrganisation: state.organisationsReducer.currentOrganisation,
  countries: state.adminAuthReducer.countries
});

export default connect(mapStateToProps)(withStyles(styles)(Suppliers));
