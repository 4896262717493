import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import SupplierCard from './SupplierCard';
import SupplierEdit from 'components/FormsPleaseStopDoingThis/SuppliersForm/SupplierEdit';
import { updateSupplier } from 'actions/supplierActions';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    flexWrap: 'wrap',
    width: '100%',
    maxHeight: "900px",
    [theme.breakpoints.down("sm")]: {
      maxHeight: 'none'
    }
  },
  container: {

  },
}));

const Overview = props => {
  const { className, supplier, countries, error, permittedToAccess } = props;
  const classes = useStyles();
  const [openEdit, setOpenEdit] = useState(false);
  const [values, setValues] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setValues({ ...supplier })
  }, [supplier]);


  const handleEditOpen = () => {
    setOpenEdit(true);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const contactFields =
    [
      { value: 'name', label: 'Company Name *', required: ['required'], errorMessages: ['required'], options: [] },
      { value: 'display_name', label: 'Display name *', required: ['required'], errorMessages: ['required'], options: [] },
      { value: 'contact_name', label: 'Contact name *', required: ['required'], errorMessages: ['required'], options: [] },
      { value: 'phone', label: 'Phone', required: ['required', 'matchRegexp:^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$', 'minStringLength:8'], errorMessages: ['required', 'Number is not valid', 'Number is not valid'], options: [] },
      { value: 'email', label: 'Email address *', required: ['required', 'isEmail'], errorMessages: ['required', 'Email is not valid'], options: [] },
      { value: 'website', label: 'Website', required: [], options: [] },
    ]

  const addressFields =
    [
      { value: 'address_line_1', label: 'Address 1 *', required: ['required'], errorMessages: ['required'], options: [] },
      { value: 'address_line_2', label: 'Address 2', required: [], options: [] },
      { value: 'suburb', label: 'Suburb *', required: ['required'], errorMessages: ['required'], options: [] },
      { value: 'postcode', label: 'Postcode *', required: ['required'], errorMessages: ['required'], options: [] },
      { value: 'state', label: 'State/Region *', required: ['required'], errorMessages: ['required'], options: [] },
      { value: 'country', label: 'Country *', required: ['required'], errorMessages: ['required'], options: countries, select: true, optionValue: 'text' },
    ]

  const handleSave = (event) => {
    event.preventDefault();

    dispatch(updateSupplier(values))
      .then(result => {
        if (result) {
          // result is null when in error
          handleEditClose()
        }
      })
  }

  const handleChange = (event) => {
    const newValues = { ...values, [event.target.name]: event.target.value }
    setValues(newValues);
  };

  return (
    <Grid
      // {...rest}
      className={clsx(classes.root, className)}
      container
      spacing={2}
      direction="column"
    >
      <Grid
        item
        md={6}
        xs={12}
      >
        <SupplierCard
          title="Supplier Info"
          supplier={supplier}
          rows={["name", "display_name", "contact_name", "phone"]}
        />

      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <SupplierCard
          title="Address Info"
          supplier={supplier}
          rows={["address_line_1", "address_line_2", "suburb", "state", "postcode", "country"]}
          edit={true}
          handleEditOpen={handleEditOpen}
          permittedToAccess={permittedToAccess}
        />
        <SupplierEdit
          onClose={handleEditClose}
          values={values}
          handleChange={handleChange}
          handleSave={handleSave}
          open={openEdit}
          contactFields={contactFields}
          addressFields={addressFields}
          title="Edit Supplier"
          error={error}
        />
      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <SupplierCard
          title="Value summary"
          supplier={supplier.details}
          rows={["orders", "skus", "skus_quantity", "total_paid", "total_sold"]}
          prefixes={['', '', '', '$', '$']}
          permittedToAccess={permittedToAccess}
        />

      </Grid>
      <Grid
        item
        md={6}
        xs={12}
      >
        <SupplierCard
          title="Other Info"
          supplier={supplier}
          rows={["website"]}
          permittedToAccess={permittedToAccess}
        />

      </Grid>
    </Grid>
  );
};

Overview.propTypes = {
  className: PropTypes.string
};

export default Overview;
