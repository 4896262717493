import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/styles';
import {
    Hidden,
    Paper,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Button
} from '@material-ui/core';

import { useSelector, useDispatch } from 'react-redux';
import { fetchInvoices } from 'actions/invoiceActions';

import Header from 'components/Header/Header';
import Can from "components/Can";
import NotAuthorised from 'views/Error401'

import moment from 'moment';

const useStyles = makeStyles(theme => ({
    root: {
      maxWidth: '100%',
      margin: '0 auto',
      padding: theme.spacing(3),
    },
    paper: {
        minHeight: 400,
        padding: theme.spacing(3),
        overflow: 'auto'
    },
    table: {
        tableLayout: 'auto'
    },
    tableCell: {
        [theme.breakpoints.down("sm")]: {
            padding: '12px 4px'
        },
        padding: '14px 40px 14px 16px'
    }

}));

const Invoices = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const invoices = useSelector(state => state.invoicesReducer.invoices);
    const currentOrganisation = useSelector(state => state.organisationsReducer.currentOrganisation);
    
    useEffect(() => {
        dispatch(fetchInvoices())
    }, [dispatch])

    return (
        <div className={classes.root}>
            <Can
                role={currentOrganisation.currentOrganisationUser.role}
                perform="invoices:view"
                yes={() => (
                   <> 
                        <Header title="Invoices"/>
                        <Paper className={classes.paper}>
                            <Typography variant='h1'>Invoices</Typography>
                            {
                                invoices ?
                                    <Table className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.tableCell}>Invoice </TableCell>
                                                <TableCell className={classes.tableCell}>Date</TableCell>
                                                <TableCell className={classes.tableCell}>Status</TableCell>
                                                <Hidden smDown><TableCell className={classes.tableCell}>Usage</TableCell></Hidden>
                                                <TableCell className={classes.tableCell}>Items</TableCell>
                                                <TableCell className={classes.tableCell}>Total</TableCell>
                                                <TableCell className={classes.tableCell}>PDF breakdown</TableCell>
                                                <TableCell className={classes.tableCell}>Tax Invoice</TableCell>
            
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {invoices.map(invoice => (
                                                <TableRow>
                                                    <TableCell className={classes.tableCell}>Invoice #{invoice.id}</TableCell>
                                                    <TableCell className={classes.tableCell}>{moment(invoice.invoice_date).format('D MMM, YYYY')}</TableCell>
                                                    <TableCell className={classes.tableCell}>{invoice.paid? 'Processed' : 'Pending'}</TableCell>
                                                    <Hidden smDown><TableCell className={classes.tableCell}>{invoice.space_usage}</TableCell></Hidden>
                                                    <TableCell className={classes.tableCell}>{invoice.num_items}</TableCell>
                                                    <TableCell className={classes.tableCell}>${Number(invoice.grand_total).toFixed(2)}</TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                    <Button
                                                        variant='outlined'
                                                        href={invoice.pdf_url ? invoice.pdf_url.url : ''}
                                                        target="_blank"
                                                        // disabled={!invoice.pdf_url.url}
                                                        disabled={moment(invoice.invoice_date) < moment('2020-02-16T00:00:00.000+08:00')}
                                                    >
                                                        View
                                                    </Button>
                                                    </TableCell>
                                                    <TableCell className={classes.tableCell}>
                                                    <Button
                                                        variant='outlined'
                                                        href={invoice.attachment_url}
                                                        target="_blank"
                                                        disabled={!invoice.attachment_url}
                                                    >
                                                        View
                                                    </Button>
                                                    </TableCell>
            
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                :
                                    <Typography variant='body1'>No invoices yet. Invoices are usually processed on Monday / Tuesday, weekly</Typography>
                            }
                        </Paper>
                    </>
                )}
                no={() => <NotAuthorised/>}
                />

            </div>
           
    )

}


export default Invoices;
