import keepspaceApi from 'services/keepspaceApi';

export const FETCH_CUSTOMERS_BEGIN = 'FETCH_CUSTOMERS_BEGIN';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE'

export const FETCH_CUSTOMER_BY_ID_BEGIN = 'FETCH_CUSTOMER_BY_ID_BEGIN';
export const FETCH_CUSTOMER_BY_ID_SUCCESS = 'FETCH_CUSTOMER_BY_ID_SUCCESS';
export const FETCH_CUSTOMER_BY_ID_FAILURE = 'FETCH_CUSTOMER_BY_ID_FAILURE'

export const CREATE_CUSTOMER_BEGIN = 'CREATE_CUSTOMER_BEGIN';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE'

export const DELETE_CUSTOMER_BEGIN = 'DELETE_CUSTOMER_BEGIN'
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS'
export const DELETE_CUSTOMER_FAILURE = 'DELETE_CUSTOMER_FAILURE'

export const UPDATE_CUSTOMER_BEGIN = 'UPDATE_CUSTOMER_BEGIN'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const UPDATE_CUSTOMER_FAILURE = 'UPDATE_CUSTOMER_FAILURE'

export const fetchCustomersBegin = () => ({
    type: FETCH_CUSTOMERS_BEGIN
});

export const fetchCustomersSuccess = customers => ({
    type: FETCH_CUSTOMERS_SUCCESS,
    payload: { customers }
});

export const fetchCustomersFailure = error => ({
    type: FETCH_CUSTOMERS_FAILURE,
    payload: { error }
});

export const fetchCustomerByIDBegin = () => ({
    type: FETCH_CUSTOMER_BY_ID_BEGIN
});

export const fetchCustomerByIDSuccess = customer => ({
    type: FETCH_CUSTOMER_BY_ID_SUCCESS,
    payload: { customer }
});

export const fetchCustomerByIDFailure = error => ({
    type: FETCH_CUSTOMER_BY_ID_FAILURE,
    payload: { error }
});

export const createCustomerBegin = () => ({
    type: CREATE_CUSTOMER_BEGIN
});

export const createCustomerSuccess = customer => ({
    type: CREATE_CUSTOMER_SUCCESS,
    payload: { customer }
});

export const createCustomerFailure = error => ({
    type: CREATE_CUSTOMER_FAILURE,
    payload: { error }
});

export const deleteCustomerBegin = () => ({
    type: DELETE_CUSTOMER_BEGIN,
});

export const deleteCustomerSuccess = (id) => ({
    type: DELETE_CUSTOMER_SUCCESS,
    payload: { id }
});

export const deleteCustomerFailure = (error) => ({
    type: DELETE_CUSTOMER_FAILURE,
    payload: { error }
});

export const updateCustomerBegin = () => ({
    type: UPDATE_CUSTOMER_BEGIN,
})

export const updateCustomerSuccess = (customer) => ({
    type: UPDATE_CUSTOMER_SUCCESS,
    payload: { customer }
})

export const updateCustomerFailure = error => ({
    type: UPDATE_CUSTOMER_FAILURE,
    payload: { error }
});


export function fetchCustomers() {
    return dispatch => {
        dispatch(fetchCustomersBegin());
        return keepspaceApi.getCustomers()
            .then(json => dispatch(fetchCustomersSuccess(json)))
            .catch(error => dispatch(fetchCustomersFailure(error)))
    };
}

export function fetchCustomerByID(id) {
    return dispatch => {
        dispatch(fetchCustomerByIDBegin());
        return keepspaceApi.getCustomerById(id)
            .then(json => dispatch(fetchCustomerByIDSuccess(json)))
            .catch(error => dispatch(fetchCustomerByIDFailure(error)))
    }
}

export function createCustomer(formData) {
    return dispatch => {
        dispatch(createCustomerBegin());
        return keepspaceApi.createCustomer(formData)
            .then(json => {
              dispatch(createCustomerSuccess(json))
              return json
            })
            .catch(error => {
              dispatch(createCustomerFailure(error))
            })
    };
}

export function deleteCustomer(id) {
    return dispatch => {
        dispatch(deleteCustomerBegin());
        return keepspaceApi.deleteCustomer(id)
            .then(() => {
              dispatch(deleteCustomerSuccess(id))
              return id
            })
            .catch(error => {
              dispatch(deleteCustomerFailure(error))
            })
    }
}

export function updateCustomer(customer) {
 return dispatch => {
        dispatch(updateCustomerBegin());
        return keepspaceApi.updateCustomer(customer)
            .then(json => {
                dispatch(updateCustomerSuccess(json));
                return json;
            })
            .catch(error => {
                dispatch(updateCustomerFailure(error))
            });
    }
}
