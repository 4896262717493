import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';

import { Page } from 'components';
import { Grid } from '@material-ui/core';
import { Results, PerformanceOverTime, OrdersDatePicker, SkuSearch } from './components';
import Header from 'components/Header/Header';
import NotAuthorised from 'views/Error401'
import checkPermission from "components/checkPermission";

import { useDispatch, useSelector } from 'react-redux';
import { searchOrdersBySKU, fetchItems } from 'actions/spaceActions';

import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    margin: 'auto',
    marginBottom: '100px',
    width: '90%'
  },
  results: {
    marginTop: theme.spacing(3)
  },
  date: {
    paddingTop: '50px'
  },
  header: {
    cursor: 'pointer',
    padding: '15px',
  },
  content: {
    borderRadius: "6px",
    opacity: "1",
    boxShadow: "0 0 0 1px rgba(6,44,82,.1),0 2px 16px rgba(33,43,54,.08)",
    WebkitBackfaceVisibility: "hidden",
    backfaceVisibility: "hidden",
    willChange: "opacity,left,top",
    transition: "opacity .2s cubic-bezier(.36,0,1,1)",
    display: 'flex',
    flexDirection: 'column',
    padding: '20px',
    marginTop: '20px'
  },
  formBox: {
    display: 'flex',
    flexDirection: 'column',
    margin: '20 px, auto',
  },
  error: {
    color: '#f44336',
    padding: '5px'
  },
  image: {
    width: '100%',
    opacity: '0.4'
  },
  padding20: {
    padding: '20px 0'
  },
  dates: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const SkuBreakdown = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(moment().subtract(365, 'days'));
  const [endDate, setEndDate] = useState(moment());

  const [openDiv, setOpenDiv] = useState(false);

  const itemStocks = useSelector(state => state.spaceReducer.itemStocks)
  const monthlyOutbounds = useSelector(state => state.spaceReducer.monthlyOutbounds)
  const error = useSelector(state => state.spaceReducer.orderBySkuError)
  const searchItem = useSelector(state => state.spaceReducer.itemSku)
  const itemList = useSelector(state => state.spaceReducer.items)
  const currentOrganisation = useSelector(state => state.organisationsReducer.currentOrganisation);

  const skuList = itemList.map(item => ({ id: item['id'], sku: item['sku'], title: item['title'] }))

  useEffect(() => {
    dispatch(fetchItems());
  }, [itemList.length, dispatch])

  const handleCalendarAccept = (date, edge) => {
    if (edge === 'start') {
      setStartDate(date);

      if (moment(date).isAfter(endDate)) {
        setEndDate(date);
      }
    } else {
      setEndDate(date);

      if (moment(date).isBefore(startDate)) {
        setStartDate(date);
      }
    }
  };

  const togglePanel = () => {
    const divState = openDiv
    setOpenDiv(!divState)
  }

  const handleSearchInput = (text, type) => {
    dispatch(searchOrdersBySKU(text, moment(startDate).format('DD/MM/YYYY'), moment(endDate).format('DD/MM/YYYY')));
  }
  
  if (!checkPermission(currentOrganisation.currentOrganisationUser.role, "analytics:view")) return <NotAuthorised/>

  return (
    <Page
      className={classes.root}
      title="Orders Management List"
    >
      <Header title="Analytics" subtitle="Sku Breakdown" />

      <Grid container className={classes.results} spacing={3}>
        <Grid item xs={12} sm={12} md={12}>

          <SkuSearch
            onSearch={handleSearchInput}
            error={error}
            inputType="id"
            skuList={skuList}
            openDiv={openDiv}
            togglePanel={togglePanel}
            classes={classes}
            handleCalendarAccept={handleCalendarAccept}
            startDate={startDate}
            endDate={endDate}
            OrdersDatePicker={OrdersDatePicker}
          />
          {error === null ? (null) : (<div className={classes.error}> Not found </div>)}


        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <PerformanceOverTime monthlyOutbounds={monthlyOutbounds} />
        </Grid>
      </Grid>


      <div className={classes.padding20}>
        {searchItem ? (
          <Results
            orders={itemStocks}
            searchItem={searchItem}
            className={classes.padding20}
            startDate={startDate.format('DD/MM/YYYY')}
            endDate={endDate.format('DD/MM/YYYY')}
          />
        ) : null}
      </div>
    </Page>
  );
};

export default SkuBreakdown;
